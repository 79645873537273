import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Link,
  Toolbar,
  Button,
  Tooltip,
  IconButton,
  // Scrollbar,
  Typography,
  Avatar,
  Drawer,
} from "@material-ui/core";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
// import Logo from "../../components/Logo";
import User from "../../icons/User";
import MenuIcon from "../../icons/Menu";
import { StyleSheet } from "@react-pdf/renderer";
import WhatsappDrawer from "./WhatsappDrawer";
import {
  ServerURL,
  LogoHeight,
  LogoWidth,
} from "../../__fakeApi__/ServerConfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PricingHeaderMobile from "./PricingHeaderMobile";
import useAuth from "../../hooks/useAuth";

const linkstyle = {
  // maxWidth: "100%",
  width: "max-content",
  textDecoration: "none",
  fontWeight: "bold",
  // marginRight:'2% !important',
  marginLeft: 1,
  padding: 1,
  // transition: "0.4",
  fontSize: 18,
  // content:"width=device-width, initial-scale=1.0",
  // fontWeight: "fontWeightBold",
  // margin:'auto',
  // marginLeft:'5%',,
  "&:hover": {
    backgroundColor: "#623e80",
    color: "white",
    opacity: 10,
    transition: "0.4s",
    borderRadius: 0.75,
    borderColor: "white",
  },
};
const btn_style = {
  backgroundColor: "#624e80",
  color: "white",
  // height: "50px",
  // width: "100px",
  width: "max-content",

  marginLeft: 3,
  "&:hover": {
    backgroundColor: "#fff",
    color: "#623e80",
    opacity: 10,
    transition: 0.5,
  },
};
const btn_mstyle = {
  backgroundColor: "#624e80",
  color: "white",
  height: "50px",
  // width: "100px",
  width: "max-content",

  // marginLeft: 3,
  "&:hover": {
    backgroundColor: "#fff",
    color: "#623e80",
    opacity: 10,
    transition: 0.5,
  },
};
const PricingHeader = (props) => {
  const location = useLocation();
  const { user } = useAuth();
  // const { contact ,  } = props;
  const { contact } = props;
  let logo = "/static/contact/qplogo.png";
  // console.log("location", location.pathname == "/" ? "#000" : "textSecondary");
  if (contact && contact.logo) {
    logo = `${ServerURL.substring(0, ServerURL.length - 1)}${contact.logo}`;
  }

  const [openMobile, onMobileClose] = useState(false);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const sections = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About US",
      path: "/about",
    },
    {
      title: "Services",
      path: "/service",
    },
    {
      title: "Find US",
      path: "/contact",
    },
    {
      title: "Price Offers",
      path: "/pricing",
    },
    // {
    //   title: "Login",
    //   path: "/",
    // },
  ];
  const content = (
    <>
      {/* // <AppBar elevation={6} sx={{ backgroundColor: "#fff", p: 2 }} {...props}>
      // <Toolbar> */}
      <Tooltip title="">
        <RouterLink to="/">
          <img
            // className="image2"
            alt="hello"
            src={logo}
            // width="110"
            // style={{ width: "110" }}
            width={LogoWidth}
            height={LogoHeight}
          />
        </RouterLink>
      </Tooltip>

      <Box sx={{ flexGrow: 1 }} />
      {sections.map((section) => {
        return (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: "auto",
              // mt: 10,
            }}
          >
            <Link
              // className="linkstyle"
              // style={styles.linkstyle}
              sx={linkstyle}
              component={RouterLink}
              color={
                location.pathname == section.path ? "#000" : "textSecondary"
              }
              to={section.path}
              underline="none"
              variant="body1"
              // sx={{ mx: 2, mr: 2,  }}
              // style={styles.linkstyle}
              // sx={{ mr: 2 }}
            >
              {section.title}
            </Link>
          </Box>
        );
      })}

      <Box
        sx={{
          // alignItems: "center",
          display: "flex",
          // justifyContent: "center",
          // p: 5,
          m: "auto",
          // mt: 10,
        }}
      >
        {location.pathname != "/login" ? (
          <RouterLink style={{ textDecoration: "none" }} to="/">
            <Button
              sx={btn_style}
              component={RouterLink}
              size="small"
              startIcon={<User fontSize="small" />}
              to="/login"
              variant="body1"
            >
              {user ? user.first_name : "Login"}
            </Button>
          </RouterLink>
        ) : (
          <Link
            color={location.pathname == "/login" ? "#000" : "textSecondary"}
            // component={RouterLink}
            // to="/pricing"
            underline="none"
            variant="body1"
            startIcon={<User fontSize="small" />}
            // sx={{ ml: -8, fontWeight: "fontWeightBold" }}
            sx={linkstyle}
            // style={styles.linkstyle}
          >
            Login
          </Link>
        )}
      </Box>
    </>
    //   {/* </Toolbar> */}
    //   {/* <Divider /> */}
    // {/* </AppBar> */}
  );

  return (
    <>
      <AppBar elevation={6} sx={{ backgroundColor: "#fff", p: 0.5 }} {...props}>
        <Toolbar>
          {/* {!lgUp && ( */}
          <Tooltip title="">
            <IconButton
              color="#000"
              onClick={() => {
                onMobileClose(true);
              }}
              sx={{
                display: {
                  lg: "none",
                },
                // display:"flex"
              }}
            >
              <MenuIcon sx={{ color: "#000" }} fontSize="large" />
              <img
                // className="image2"
                alt="hello"
                src={logo}
                // width="110"
                style={{ marginLeft:"10px"}}
                width={LogoWidth}
                height={LogoHeight}
              />
            </IconButton>
          </Tooltip>
          {/* )} */}
          {lgUp && content}
        </Toolbar>
        {/* <Divider /> */}
        <Drawer
          anchor="left"
          onClose={() => onMobileClose(false)}
          open={openMobile}
          size="small"
          PaperProps={{
            sx: {
              backgroundColor: "#fff",
              width: 300,
            },
          }}
          variant="temporary"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Scrollbar options={{ suppressScrollX: true }}>
              <Box
                sx={{
                  display: {
                    lg: "none",
                    xs: "flex",
                  },
                  justifyContent: "center",
                  // p: 2,
                  mt: 5,
                }}
              >
                <RouterLink to="/">
                  <img
                    // className="image2"
                    alt="hello"
                    src={logo}
                    // width="110"
                    // style={{ width: "110" }}
                    width={LogoWidth}
                    height={LogoHeight}
                  />
                </RouterLink>
              </Box>
              <Box sx={{ p: 2 }}>
                {user ? (
                  <Box
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#fff",
                      borderRadius: 1,
                      justifyContent: "center",
                      display: "flex",
                      overflow: "hidden",
                      // p: 2,
                    }}
                  >
                    <RouterLink to="/login">
                      <Avatar
                        src={user.avatar}
                        sx={{
                          cursor: "pointer",
                          height: 48,
                          width: 48,
                          color: "white",
                        }}
                      />
                    </RouterLink>

                    <Box sx={{ ml: 2 }}>
                      <Typography color="#000" variant="h5">
                        {user.first_name
                          ? `${user.first_name} ${user.last_name || ""}`
                          : user.name}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      // p: 5,
                      m: 1,
                      // mt: 10,
                    }}
                  >
                    {location.pathname != "/login" ? (
                      <RouterLink style={{ textDecoration: "none" }} to="/">
                        <Button
                          sx={btn_mstyle}
                          component={RouterLink}
                          size="small"
                          startIcon={<User fontSize="small" />}
                          to="/login"
                          variant="body1"
                        >
                          {user ? user.first_name : "Login"}
                        </Button>
                      </RouterLink>
                    ) : (
                      <Link
                        color={
                          location.pathname == "/login"
                            ? "#000"
                            : "textSecondary"
                        }
                        // component={RouterLink}
                        // to="/pricing"
                        underline="none"
                        variant="body1"
                        startIcon={<User fontSize="small" />}
                        // sx={{ ml: -8, fontWeight: "fontWeightBold" }}
                        sx={linkstyle}
                        // style={styles.linkstyle}
                      >
                        Login
                      </Link>
                    )}
                  </Box>
                )}
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                {sections.map((section) => {
                  return (
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        m: "auto",
                        // mt: 10,
                      }}
                    >
                      <Link
                        // className="linkstyle"
                        // style={styles.linkstyle}
                        sx={linkstyle}
                        component={RouterLink}
                        color={
                          location.pathname == section.path
                            ? "#000"
                            : "textSecondary"
                        }
                        to={section.path}
                        underline="none"
                        variant="body1"
                        // sx={{ mx: 2, mr: 2,  }}
                        // style={styles.linkstyle}
                        // sx={{ mr: 2 }}
                      >
                        {section.title}
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            </Scrollbar>
          </Box>
        </Drawer>
      </AppBar>
    </>
  );
};
// };

export default PricingHeader;
