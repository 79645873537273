import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BlogLayout from './components/blog/BlogLayout';
import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import CustomerDashboardLayout from './components/dashboard/CustomerDashboardLayout';

import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import PricingPage from './pages/pricingpage/PricingPage';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages PriceList

const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
const BrowseButtons = Loadable(
  lazy(() => import('./pages/browse/BrowseButtons')),
);
const BrowseCharts = Loadable(
  lazy(() => import('./pages/browse/BrowseCharts')),
);
const BrowseColors = Loadable(
  lazy(() => import('./pages/browse/BrowseColors')),
);
const BrowseDetailLists = Loadable(
  lazy(() => import('./pages/browse/BrowseDetailLists')),
);
const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
const BrowseGridLists = Loadable(
  lazy(() => import('./pages/browse/BrowseGridLists')),
);
const BrowseGroupedLists = Loadable(
  lazy(() => import('./pages/browse/BrowseGroupedLists')),
);
const BrowseInputs = Loadable(
  lazy(() => import('./pages/browse/BrowseInputs')),
);
const BrowseModals = Loadable(
  lazy(() => import('./pages/browse/BrowseModals')),
);
const BrowseQuickStats = Loadable(
  lazy(() => import('./pages/browse/BrowseQuickStats')),
);
const BrowseTables = Loadable(
  lazy(() => import('./pages/browse/BrowseTables')),
);
const BrowseTypography = Loadable(
  lazy(() => import('./pages/browse/BrowseTypography')),
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery')),
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset')),
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register')),
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode')),
);

// Blog pages

const BlogPostCreate = Loadable(
  lazy(() => import('./pages/blog/BlogPostCreate')),
);
const BlogPostDetails = Loadable(
  lazy(() => import('./pages/blog/BlogPostDetails')),
);
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const CustomerDetails = Loadable(
  lazy(() => import('./pages/dashboard/CustomerDetails')),
);
const CustomerEdit = Loadable(
  lazy(() => import('./pages/dashboard/CustomerEdit')),
);
const CustomerList = Loadable(
  lazy(() => import('./pages/dashboard/CustomerList')),
);
const CustomerCreate = Loadable(
  lazy(() => import('./pages/dashboard/CustomerCreate')),
);
const CustomerPriceList = Loadable(
  lazy(() => import('./pages/dashboard/CustomerPriceList')),
);
const CustomerWallet = Loadable(
  lazy(() => import('./pages/dashboard/CustomerWallet')),
);
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceDetails')),
);
const InvoiceList = Loadable(
  lazy(() => import('./pages/dashboard/InvoiceList')),
);
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(
  lazy(() => import('./pages/dashboard/OrderDetails')),
);
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));

//rejected orders
const RejectedOrderList = Loadable(
  lazy(() => import('./components/dashboard/rejected_orders/OrderList')),
);

const CancelledOrderList = Loadable(
  lazy(() => import('./components/dashboard/order/CanceledList')),
);

const ArchivedOrderList = Loadable(
  lazy(() =>
    import('./components/dashboard/archived_orders/ArchivedOrderList'),
  ),
);
const ArchiveOrderPage = Loadable(
  lazy(() => import('./components/dashboard/archived_orders/ArchiveOrderPage')),
);


const TaxableOrderPage = Loadable(
  lazy(() => import('./components/dashboard/taxable_orders/TaxableOrderPage')),
);
const OrderPermissionPage = Loadable(
  lazy(() => import('./components/dashboard/orders_permissions/OrderPermissionPage')),
);



const ExpensesReport = Loadable(
  lazy(() => import('./components/dashboard/ExpensesReport/ExpensesReport')),
);
const UnactiveCustomersReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/UnactiveCustomersReport/UnactiveCustomersReport'
    ),
  ),
);

const ShipperClosingRequestReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ShipperClosingRequestReport/ShipperClosingRequestReport'
    ),
  ),
);

const ShipperClosingRequestDetails = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ShipperClosingRequestReport/ShipperClosingRequestDetails'
    ),
  ),
);
const AssignOrderToShipperList = Loadable(
  lazy(() =>
    import(
      './components/dashboard/assign_order_to_shipper/AssignOrderToShipperList'
    ),
  ),
);
const ReturnOrderCustomerList = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ReturnOrderCustomer/ReturnOrderCustomerList'
    ),
  ),
);

const OrderListAdmin = Loadable(
  lazy(() => import('./pages/dashboard/OrderList')),
);
const OrderEdit = Loadable(lazy(() => import('./pages/dashboard/OrderEdit')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const OrderCreate = Loadable(
  lazy(() => import('./pages/dashboard/OrderCreate')),
);
const ProductCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProductCreate')),
);
const ProductList = Loadable(
  lazy(() => import('./pages/dashboard/ProductList')),
);
const ClientShipmentInfoList = Loadable(
  lazy(() => import('./pages/dashboard/ClientShipmentInfoList')),
);
const CustomerReturnPriceList = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerReturnPriceList/PriceList'),
  ),
);

const ReturnRequestList = Loadable(
  lazy(() => import('./components/dashboard/return_request/ReturnRequestList')),
);
const ReturnRequestListDetails = Loadable(
  lazy(() =>
    import('./components/dashboard/return_request/ReturnRequestListDetails'),
  ),
);

const CustomerShipmentInfoCreate = Loadable(
  lazy(() => import('./pages/dashboard/CustomerShipmentInfoCreate')),
);
const Modal1 = Loadable(
  lazy(() => import('./components/widgets/modals/Modal1')),
);

// issue page
const IssuePage = Loadable(
  lazy(() => import('./components/dashboard/issue/IssuePage')),
);
const CustomerIssuePage = Loadable(
  lazy(() => import('./components/dashboard/customer_issue/CustomerIssuePage')),
);

//isue report
const IssueReport = Loadable(
  lazy(() => import('./components/dashboard/issue_report/IssueReport')),
);

// exra expenses report
const ExtraExpensesReport = Loadable(
  lazy(() => import('./components/dashboard/expense_report/ExpensesReport')),
);
const TodaysOrder = Loadable(
  lazy(() => import('./components/dashboard/todays_order/OrderList')),
);
//Admin Order
const AdminOrderList = Loadable(
  lazy(() => import('./components/dashboard/adminorder/AdminOrderList')),
);
const AdminOrderNew = Loadable(
  lazy(() => import('./components/dashboard/adminorder/AdminOrderNew')),
);
const AdminOrderNewWindow = Loadable(
  lazy(() => import('./components/dashboard/order/AdminOrderNew')),
);
const AdminOrderSwitchWindow = Loadable(
  lazy(() => import('./components/dashboard/adminorder/AdminOrderSwitch')),
);
const AdminOrderEdit = Loadable(
  lazy(() => import('./components/dashboard/adminorder/AdminOrderEdit')),
);
const ShipperStatusFollowReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ShipperStatusFollow/ShipperStatusFollowReport'
    ),
  ),
);
const ReturnOrderShipper = Loadable(
  lazy(() =>
    import('./components/dashboard/ReturnOrderShipper/ReturnOrderShipperList'),
  ),
);

/////////////////////////////////////////////////////////////////////////////////////
// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired')),
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const ProjectBrowse = Loadable(
  lazy(() => import('./pages/dashboard/ProjectBrowse')),
);
const ProjectCreate = Loadable(
  lazy(() => import('./pages/dashboard/ProjectCreate')),
);
const ProjectDetails = Loadable(
  lazy(() => import('./pages/dashboard/ProjectDetails')),
);

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(
  lazy(() => import('./pages/dashboard/SocialProfile')),
);

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./components/contacts/Contacts')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

// New Pages Added ConfigurationsEdit
const SiteSettings = Loadable(
  lazy(() => import('./components/dashboard/SiteSettings/SiteSettingsEdit')),
);
const PriceList = Loadable(
  lazy(() => import('./components/dashboard/Pricing/PricingList')),
);
const PricingNew = Loadable(
  lazy(() => import('./components/dashboard/Pricing/PricingNew')),
);
const PricingEdit = Loadable(
  lazy(() => import('./components/dashboard/Pricing/PricingEdit')),
);

// City Pages

const CityList = Loadable(
  lazy(() => import('./components/dashboard/city/CityList')),
);
const TodaysOrdersPage = Loadable(
  lazy(() => import('./components/dashboard/TodaysOrders/TodaysOrdersPage')),
);

const CityNew = Loadable(
  lazy(() => import('./components/dashboard/city/CityNew')),
);
const CityEdit = Loadable(
  lazy(() => import('./components/dashboard/city/CityEdit')),
);

// Hold Reason
const HoldList = Loadable(
  lazy(() => import('./components/dashboard/HoldReason/HoldList')),
);
const HoldNew = Loadable(
  lazy(() => import('./components/dashboard/HoldReason/HoldNew')),
);
const HoldEdit = Loadable(
  lazy(() => import('./components/dashboard/HoldReason/HoldEdit')),
);
// Hold Reason
const CustomerCategoryList = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerCategory/CustomerCategoryList'),
  ),
);
const CustomerCategoryNew = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerCategory/CustomerGroupNew'),
  ),
);
const CustomerCategoryEdit = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerCategory/CustomerGroupEdit'),
  ),
);

//HangonReason

const HangonReasonList = Loadable(
  lazy(() => import('./components/dashboard/HangonReason/HangonReasonList')),
);
const HangonReasonNew = Loadable(
  lazy(() => import('./components/dashboard/HangonReason/HangonReasonNew')),
);
const HangonReasonEdit = Loadable(
  lazy(() => import('./components/dashboard/HangonReason/HangonReasonEdit')),
);

// Zone Pages

const ZoneList = Loadable(
  lazy(() => import('./components/dashboard/zone/ZoneList')),
);
const ZoneNew = Loadable(
  lazy(() => import('./components/dashboard/zone/ZoneNew')),
);
const ZoneEdit = Loadable(
  lazy(() => import('./components/dashboard/zone/ZoneEdit')),
);

// Area Pages

const AreaList = Loadable(
  lazy(() => import('./components/dashboard/area/AreaList')),
);
const AreaNew = Loadable(
  lazy(() => import('./components/dashboard/area/AreaNew')),
);
const AreaEdit = Loadable(
  lazy(() => import('./components/dashboard/area/AreaEdit')),
);

// Database Pages

const DatabaseList = Loadable(
  lazy(() => import('./components/dashboard/Database/DatabaseList')),
);

// Shipper Pages

const ShipperList = Loadable(
  lazy(() => import('./components/dashboard/shipper/ShipperList')),
);
const ShipperNew = Loadable(
  lazy(() => import('./components/dashboard/shipper/ShipperNew')),
);
const ShipperEdit = Loadable(
  lazy(() => import('./components/dashboard/shipper/ShipperEdits')),
);

// shipper closing
const ShipperClosingNew = Loadable(
  lazy(() => import('./components/dashboard/shipperclosing/ShipperClosingNew')),
);
const ShipperClosingView = Loadable(
  lazy(() =>
    import('./components/dashboard/shipperclosing/ShipperClosingView'),
  ),
);
const ShipperClosingViewDetails = Loadable(
  lazy(() =>
    import('./components/dashboard/shipperclosing/ShipperClosingViewDetails'),
  ),
);

// shipper Evaluation Report
const ShipperEvaluationReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ShipperEvaluationsReport/ShipperEvaluationReport'
    ),
  ),
);

///user evaluation breport
const DetailedUserEvaluationReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/user_evaluation_report/DetailedShipperEvaluaionReport'
    ),
  ),
);
const UserEvaluationReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/user_evaluation_report/ShipperEvaluationReport'
    ),
  ),
);
const TopCompaniesByOrderReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/TopCompaniesByOrderReport/TopCompaniesByOrderReport'
    ),
  ),
);
const TopCompaniesByOrderReportdetails = Loadable(
  lazy(() =>
    import(
      './components/dashboard/TopCompaniesByOrderReport/TopCompaniesByOrderDetailsReport'
    ),
  ),
);
const TopCompaniesByShipperDetails = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CompaniesOrdersByShipper/TopCompaniesByShipperDetailsReport'
    ),
  ),
);
const TopCompaniesByCityReportdetails = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CompaniesOrdersByCity/TopCompaniesByCityDetailsReport'
    ),
  ),
);

const CustomerOrdersByCityReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerOrdersByCity/CustomerOrdersByCityReport'
    ),
  ),
);

const CustomerOrdersByCityDetailsReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerOrdersByCity/TopCompaniesByCityDetailsReport'
    ),
  ),
);

//detailed shipper evaluation
const DetailedShipperEvaluationReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ShipperEvaluationsReport/DetailedShipperEvaluaionReport'
    ),
  ),
);

const CompaniesOrdersByShipperReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CompaniesOrdersByShipper/CompaniesOrdersByShipperReport'
    ),
  ),
);
const CompaniesOrdersByCityReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CompaniesOrdersByCity/CompaniesOrdersByCityReport'
    ),
  ),
);

// Shipper Revenue
const ShipperRevenueReport = Loadable(
  lazy(() =>
    import('./components/dashboard/ShipperRevenue/ShipperRevenueReport'),
  ),
);
const ShipperRevenueDetailReport = Loadable(
  lazy(() =>
    import('./components/dashboard/ShipperRevenue/ShipperRevenueDetailReport'),
  ),
);

// Shipper Revenue
const ShipperRevenueNewReport = Loadable(
  lazy(() =>
    import('./components/dashboard/ShipperRevenueNew/ShipperRevenueReport'),
  ),
);
const ShipperRevenueDetailNewReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ShipperRevenueNew/ShipperRevenueDetailReport'
    ),
  ),
);

// Customer Revenue
const CustomerRevenueReport = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerRevenue/CustomerRevenueReport'),
  ),
);
const NewCustomerRevenueReport = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerRevenueNew/CustomerRevenueReport'),
  ),
);

const CustomerRevenueDetailReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerRevenue/CustomerRevenueDetailReport'
    ),
  ),
);
const NewCustomerRevenueDetailReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerRevenueNew/CustomerRevenueDetailReport'
    ),
  ),
);

const CustomerRevenueSearch = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerRevenue/CustomerRevenueSearch'),
  ),
);

// Customer Revenue for customer
const CustomerRevenuseReport = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerRevenues/CustomerRevenueReport'),
  ),
);
const CustomerRevenuesDetailReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerRevenues/CustomerRevenueDetailReport'
    ),
  ),
);

// Configurations
const ConfigurationsEdit = Loadable(
  lazy(() =>
    import('./components/dashboard/Configurations/ConfigurationsEdit'),
  ),
);
//backup
const CreateBackup = Loadable(
  lazy(() => import('./components/dashboard/backup/BackupPage')),
);
const MaterialRequest = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerMaterialRequest/MaterialRequestPage'
    ),
  ),
);
const BackupList = Loadable(
  lazy(() => import('./components/dashboard/backup/BackupListPage')),
);
const MaterialRequestList = Loadable(
  lazy(() =>
    import('./components/dashboard/material_request/MaterialListPage'),
  ),
);
// collect request
const CollectRequest = Loadable(
  lazy(() =>
    import('./components/dashboard/CustomerCollectRequest/CollectRequestPage'),
  ),
);
const CollectList = Loadable(
  lazy(() =>
    import('./components/dashboard/collect_Request/CollectRequestList'),
  ),
);
//permisision /useraccount

const Permission = Loadable(
  lazy(() => import('./components/dashboard/permissions/permisionList')),
);
const PermissionNew = Loadable(
  lazy(() => import('./components/dashboard/permissions/permissionnew')),
);
const PermissionEdit = Loadable(
  lazy(() => import('./components/dashboard/permissions/PermissionEdit')),
);
const ShipperUserPermission = Loadable(
  lazy(() =>
    import(
      './components/dashboard/ShipperUserPermission/ShipperUserPermission'
    ),
  ),
);
const CustomerUserPermission = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerUserPermission/CustomerUserPermission'
    ),
  ),
);

const UserGroupPermission = Loadable(
  lazy(() =>
    import('./components/dashboard/user_evaluation_permissions/UserList'),
  ),
);

const UserEvaluation = Loadable(
  lazy(() => import('./components/dashboard/UserEvaluation/UserEvaluation')),
);
const FollowUpPermissions = Loadable(
  lazy(() =>
    import('./components/dashboard/follow_setting/ShipperUserPermission'),
  ),
);

const ShipperSwapPermissions = Loadable(
  lazy(() =>
    import('./components/dashboard/ShipperSwap/ShipperUserPermission'),
  ),
);

const UserPermissionFeildValueList = Loadable(
  lazy(() =>
    import(
      './components/dashboard/UserPermissionFeildValue/UserPermissionFeildValueList'
    ),
  ),
);
const UserList = Loadable(
  lazy(() => import('./components/dashboard/useraccount/permisionList')),
);
const UserAccount = Loadable(
  lazy(() => import('./components/dashboard/users/createuser')),
);
const CreateUser = Loadable(
  lazy(() => import('./components/dashboard/useraccount/CreateUser')),
);

const EditUserAccount = Loadable(
  lazy(() => import('./components/dashboard/useraccount/edituser')),
);
const SmsHoldOrders = Loadable(
  lazy(() => import('./components/dashboard/SmsHoldOrders/SmsHoldOrdersPage')),
);
const SmsReport = Loadable(
  lazy(() => import('./components/dashboard/SmsReport/SmsReport')),
);

//field permissions
const FieldPermissionList = Loadable(
  lazy(() =>
    import('./components/dashboard/field_permissions/FieldPermissionsList'),
  ),
);
const ShipperOrderReport = Loadable(
  lazy(() =>
    import('./components/dashboard/ShipperOrderReport/ShipperOrderReport'),
  ),
);

// shipping request
const ShippingRequestList = Loadable(
  lazy(() =>
    import('./components/dashboard/shipping_request/ShippingRequestList'),
  ),
);

// customer analytics
const CustomerAnalytics = Loadable(
  lazy(() => import('./components/dashboard/customer_analytics/Analytics')),
);

// Approval List

const RepeatedOrders = Loadable(
  lazy(() => import('./components/dashboard/repeated_orders/RepeatedOrders')),
);
const ApprovalList = Loadable(
  lazy(() => import('./components/dashboard/EditApproval/ApprovalList')),
);

const CustomerApprovalList = Loadable(
  lazy(() =>
    import('./components/dashboard/customer_edit_approval/ApprovalList'),
  ),
);

//// shipper edit approval
const ShipperApprovalList = Loadable(
  lazy(() =>
    import('./components/dashboard/shipper_edit_approval/ApprovalList'),
  ),
);
//////// pricing
const PricingPagehome = Loadable(
  lazy(() => import('./pages/pricingpage/PricingPage')),
);

///////////  new home
const NewMyHome = Loadable(
  lazy(() => import('./components/dashboard/home/Home')),
);
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/dashboard')));

const Service = Loadable(lazy(() => import('./pages/service/Service')));
const About = Loadable(lazy(() => import('./pages/about/About')));

///whatsapp group
const WhatsAppGroup = Loadable(
  lazy(() => import('./components/dashboard/group_list/GroupList')),
);

// hurry order
const HurryList = Loadable(
  lazy(() => import('./components/dashboard/hurry_list/CollectRequestList')),
);
const Gallery = Loadable(
  lazy(() => import('./components/dashboard/order_images/CollectRequestList')),
);
const CollectGallery = Loadable(
  lazy(() =>
    import('./components/dashboard/CollectGallery/CollectGalleryList'),
  ),
);
//folow orders
const FollowOrders = Loadable(
  lazy(() => import('./components/dashboard/follow_orders/CollectRequestList')),
);
const FollowOrdersReport = Loadable(
  lazy(() =>
    import('./components/dashboard/follow_orders_report/FollowReportList'),
  ),
);

const ShipperOrdersReview = Loadable(
  lazy(() =>
    import('./components/dashboard/shipperOrdersReview/shipperOrdersReview'),
  ),
);

/// shipper transfer report
const ShipperTransferReport = Loadable(
  lazy(() =>
    import(
      './components/dashboard/shipper_transfer_report/ShipperTransferReport'
    ),
  ),
);
const ShipperTracking = Loadable(
  lazy(() =>
    import('./components/dashboard/shipper_traking/CollectRequestList'),
  ),
);

// new pages

const NewContact = Loadable(
  lazy(() => import('./newPages/ContactUsComponent')),
);

const NewHome = Loadable(lazy(() => import('./newPages/HomeComponent')));

const NewPrice = Loadable(
  lazy(() => import('./newPages/PriceOffersComponent')),
);

const MaterialList = Loadable(
  lazy(() => import('./components/dashboard/material/materialList')),
);

const MaterialNew = Loadable(
  lazy(() => import('./components/dashboard/material/MaterialNew')),
);
const MaterialUpdate = Loadable(
  lazy(() => import('./components/dashboard/material/MaterialEdit')),
);

const WarehouseList = Loadable(
  lazy(() => import('./components/dashboard/warehouse/WarehouseList')),
);

const WarehouseNew = Loadable(
  lazy(() => import('./components/dashboard/warehouse/WarehouseNew')),
);

const WarehouseUpdate = Loadable(
  lazy(() => import('./components/dashboard/warehouse/WarehouseEdit')),
);

const StockEntryList = Loadable(
  //StockEntryEdit
  lazy(() => import('./components/dashboard/stockEntry/StockEntryList')),
);

const StockEntryNew = Loadable(
  lazy(() => import('./components/dashboard/stockEntry/StockEntryNew')),
);

const StockEntryEdit = Loadable(
  //StockEntryEdit
  lazy(() => import('./components/dashboard/stockEntry/StockEntryEdit')),
);

const StockBalanceReport = Loadable(
  //StockBalance
  lazy(() => import('./components/dashboard/StockBalance/StockBalanceReport')),
);
const StockReport = Loadable(
  //StockReport
  lazy(() => import('./components/dashboard/stock_report/StockReport')),
);

const CustomerMaterialLimitList = Loadable(
  //CustomerMaterialLimitList
  lazy(() =>
    import(
      './components/dashboard/CustomerMaterialLimit/CustomerMaterialLimitList'
    ),
  ),
);

//////////
const CustomerMaterialDifference = Loadable(
  lazy(() =>
    import(
      './components/dashboard/CustomerMaterialDifference/CustomerMaterialDifference'
    ),
  ),
);
//////////////

const CustomerMaterialBalanceReport = Loadable(
  //CustomerMaterialLimitList
  lazy(() =>
    import(
      './components/dashboard/customer_balance_report/CustomerMaterialBalanceReport'
    ),
  ),
);

const routes = [
  {
    path: '*',
    children: [
      {
        path: '',
        element: (
          //(
          //<GuestGuard>
          <NewMyHome />
        ),
        // </GuestGuard>
        //),
      },
      // {
      //   path: "NewHome",
      //   element: <NewHome />,
      // },
      // {
      //   path: "NewPrice",
      //   element: <NewPrice />,
      // },
      // {
      //   path: "NewContact",
      //   element: <NewContact />,
      // },
      // {
      //   path:"kazem",
      //   element: <NewMyHome/>
      // },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'register-unguarded',
        element: <Register />,
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  // {
  //   path: '*',
  //   element: <BlogLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <BlogPostList />
  //     },
  //     {
  //       path: 'new',
  //       element: <BlogPostCreate />
  //     },
  //     {
  //       path: ':postId',
  //       element: <BlogPostDetails />
  //     }
  //   ]
  // },
  {
    path: 'Pricing',
    element: <PricingPagehome />,
  },
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'service',
    element: <Service />,
  },
  {
    path: 'about',
    element: <About />,
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      {
        path: 'useraccount',
        // element: <UserList />,
        children: [
          {
            path: '',
            element: <UserList />,
          },
          {
            path: 'new',
            element: <CreateUser />,
          },
          {
            path: 'edit/:id',
            element: <EditUserAccount />,
          },
        ],
      },
      {
        path: 'userprivient',
        // element: <UserList />,
        children: [
          {
            path: '',
            element: <Permission />,
          },
          {
            path: 'new',
            element: <PermissionNew />,
          },
          {
            path: 'edit/:id',
            element: <PermissionEdit />,
          },
          {
            path: 'ShipperUserPermission',
            element: <ShipperUserPermission />,
          },
          {
            path: 'shipperswappermission', //ShipperSwapPermissions
            element: <ShipperSwapPermissions />,
          },
          {
            path: 'CustomerUserPermission',
            element: <CustomerUserPermission />,
          },
          {
            path: 'followupperm',
            element: <FollowUpPermissions />,
          },
          {
            path: 'grouppermission', //usergrouppermission
            element: <UserGroupPermission />,
          },
        ],
      },
      {
        path: 'ExpensesReport',
        element: <ExpensesReport />,
      },
      {
        path: 'UnactiveCustomersReport',
        element: <UnactiveCustomersReport />,
      },

      {
        path: 'ShipperCustodyReport',
        element: <ShipperOrderReport />,
      },
      {
        path: 'ShipperClosingRequestReport',
        element: <ShipperClosingRequestReport />,
      },
      {
        path: 'ShipperClosingRequestDetails/:request_id',
        element: <ShipperClosingRequestDetails />,
      },

      {
        path: 'UserEvaluation',
        element: <UserEvaluation />,
      },
      // {
      //   path: '/userprivient', //PermissionNew
      //   element: <Permission />
      // },
      {
        path: 'SmsHoldOrders',
        element: <SmsHoldOrders />,
      },
      {
        path: 'SmsReport',
        element: <SmsReport />,
      },

      {
        path: 'userpermissionfieldValue',
        element: <UserPermissionFeildValueList />,
      },
      {
        path: 'fieldPermissionlist', //FieldPermissionList
        element: <FieldPermissionList />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      //   {
      //   path: 'packip',
      //   element: <Account />
      // },
      {
        path: 'analytics',
        element: <Analytics />,
      },
      // {
      //   //CustomerAnalytics
      //   path: "customeranalytics",
      //   element: <CustomerAnalytics />,
      // },
      {
        //CustomerAnalytics
        path: 'hurryorder',
        element: <HurryList />,
      },
      {
        //CustomerAnalytics
        path: 'hurryorder/:id',
        element: <HurryList />,
      },
      {
        path: 'AssignOrderToShipper',
        element: <AssignOrderToShipperList />,
      },
      {
        path: 'ReturnOrderShipper',
        element: <ReturnOrderShipper />,
      },
      {
        path: 'ReturnOrderCustomer',
        element: <ReturnOrderCustomerList />,
      },

      {
        path: 'followorders',
        element: <FollowOrders />,
      },
      {
        path: 'followordersreport',
        element: <FollowOrdersReport />,
      },

      {
        path: 'shipperOrdersReview',
        element: <ShipperOrdersReview />,
      },
      {
        path: 'shippertracking',
        element: <ShipperTracking />,
      },
      {
        path: 'calendar',
        element: <Calendar />,
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            element: <Chat />,
          },
          {
            path: 'new',
            element: <Chat />,
          },
          {
            path: ':threadKey',
            element: <Chat />,
          },
        ],
      },
      {
        path: 'issue', //CustomerIssuePage
        children: [
          {
            path: '',
            element: <IssuePage />,
          },
          {
            path: ':threadKey',
            element: <IssuePage />,
          },
        ],
      },
      {
        path: 'WhatsAppGroup', //CustomerIssuePage
        children: [
          {
            path: '',
            element: <WhatsAppGroup />,
          },
        ],
      },
      // {
      //   path: "customerissue", //CustomerIssuePage
      //   children: [
      //     {
      //       path: "",
      //       element: <CustomerIssuePage />,
      //     },
      //     {
      //       path: ":threadKey",
      //       element: <CustomerIssuePage />,
      //     },
      //   ],
      // },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <CustomerList />,
          },
          {
            path: 'wallet',
            element: <CustomerWallet />,
          },
          {
            path: 'new',
            element: <CustomerCreate />,
          },
          {
            path: 'detail/:customerId',
            element: <CustomerDetails />,
          },
          {
            path: 'edit/:customerId',
            element: <CustomerEdit />,
          },
          {
            path: 'revenue',
            element: <CustomerRevenueReport />,
          },
          {
            path: 'revenues/:from_date/:to_date',
            element: <CustomerRevenueReport />,
          },
          {
            path: 'revenue/:id/:customer_name',
            element: <CustomerRevenueReport />,
          },
          {
            path: 'revenue/details/:customer/:day_date',
            element: <CustomerRevenueDetailReport />,
          },
          {
            path: 'newrevenue',
            element: <NewCustomerRevenueReport />,
          },
          {
            path: 'newrevenue/:id/:customer_name',
            element: <NewCustomerRevenueReport />,
          },

          {
            path: 'newrevenue/details/:customer/:day_date',
            element: <NewCustomerRevenueDetailReport />,
          },
          // ,

          // {
          //   path:'/revenue/CustomerRevenueSearch',
          //   element: <CustomerRevenueSearch />
          // }
        ],
      },

      {
        path: 'Shipment',
        children: [
          {
            path: '',
            element: <ClientShipmentInfoList />,
          },
          {
            path: ':id/:name/:address/:company_name',
            element: <ClientShipmentInfoList />,
          },
          // {
          //   path: "customerpricelist",
          //   element: <CustomerPriceList />,
          // },

          {
            path: 'CustomerShipmentInfoCreate/modal1',
            element: <Modal1 />,
          },
          //   {
          //   path: '/create',
          //   element: <CustomerShipmentInfoCreate />
          // },
        ],
      },
      {
        path: 'ReturnPriceList',
        children: [
          {
            path: ':id',
            element: <CustomerReturnPriceList />,
          },
        ],
      },
      {
        path: 'ReturnRequest',
        children: [
          {
            path: '',
            element: <ReturnRequestList />,
          },
          {
            path: 'details/:id',
            element: <ReturnRequestListDetails />,
          },
        ],
      },
      // {
      //   path: "expensesreport", //ExtraExpensesReport
      //   children: [
      //     {
      //       path: "",
      //       element: <ExtraExpensesReport />,
      //     },
      //   ],
      // },
      // {
      //   path: "revenue", //ExtraExpensesReport
      //   children: [
      //     {
      //       path: "",
      //       element: <CustomerRevenuseReport />,
      //     },
      //     {
      //       path: ":from_date/:to_date",
      //       element: <CustomerRevenuseReport />,
      //     },
      //     {
      //       path: "details/:customer/:day_date",
      //       element: <CustomerRevenuesDetailReport />,
      //     },
      //   ],
      // },
      {
        path: 'City',
        children: [
          {
            path: '',
            element: <CityList />,
          },
          {
            path: 'new',
            element: <CityNew />,
          },
          {
            path: 'edit/:id',
            element: <CityEdit />,
          },
        ],
      },
      {
        path: 'holdreason',
        children: [
          {
            path: '',
            element: <HoldList />,
          },
          {
            path: 'new',
            element: <HoldNew />,
          },
          {
            path: 'edit/:id',
            element: <HoldEdit />,
          },
        ],
      },
      {
        path: 'customercategory',
        children: [
          {
            path: '',
            element: <CustomerCategoryList />,
          },
          {
            path: 'new',
            element: <CustomerCategoryNew />,
          },
          {
            path: 'edit/:id',
            element: <CustomerCategoryEdit />,
          },
        ],
      },
      {
        path: 'HangonReason',
        children: [
          {
            path: '',
            element: <HangonReasonList />,
          },
          {
            path: 'new',
            element: <HangonReasonNew />,
          },
          {
            path: 'edit/:id',
            element: <HangonReasonEdit />,
          },
        ],
      },
      {
        path: 'Zone',
        children: [
          {
            path: '',
            element: <ZoneList />,
          },
          {
            path: 'new',
            element: <ZoneNew />,
          },
          {
            path: 'edit/:id',
            element: <ZoneEdit />,
          },
        ],
      },
      {
        path: 'area',
        children: [
          {
            path: '',
            element: <AreaList />,
          },
          {
            path: 'new',
            element: <AreaNew />,
          },
          {
            path: 'edit/:id',
            element: <AreaEdit />,
          },
        ],
      },
      {
        path: 'DatabaseList',
        children: [
          {
            path: '',
            element: <DatabaseList />,
          },
        ],
      },

      {
        path: 'shipperclosing',
        children: [
          {
            path: 'new',
            element: <ShipperClosingNew />,
          },
          {
            path: 'view',
            element: <ShipperClosingView />,
          },
          {
            path: 'view/details',
            element: <ShipperClosingViewDetails />,
          },
          {
            path: 'view/details/:shipper/:closing_date/:rc',
            element: <ShipperClosingViewDetails />,
          },
        ],
      },
      {
        path: 'approvallist',
        children: [
          {
            path: '',
            element: <ApprovalList />,
          },
        ],
      },
      {
        path: 'editapprovallist', //CustomerApprovalList
        children: [
          {
            path: '',
            element: <CustomerApprovalList />,
          },
        ],
      },
      {
        path: 'shipperapprovallist',
        children: [
          {
            path: '',
            element: <ShipperApprovalList />,
          },
        ],
      },

      {
        path: 'shipperevaluationreport',
        children: [
          //DetailedShipperEvaluationReport
          {
            path: 'view',
            element: <ShipperEvaluationReport />,
          },
          {
            path: 'detail',
            element: <DetailedShipperEvaluationReport />,
          },
        ],
      },
      {
        path: 'shippertransferreport',
        element: <ShipperTransferReport />,
      },
      {
        path: 'userevalation',
        children: [
          //DetailedShipperEvaluationReport
          {
            path: 'view',
            element: <UserEvaluationReport />,
          },
          {
            path: 'detail',
            element: <DetailedUserEvaluationReport />,
          },
        ],
      },
      {
        path: 'issuereport', //issueReport
        children: [
          //DetailedShipperEvaluationReport
          {
            path: '',
            element: <IssueReport />,
          },
        ],
      },
      {
        path: 'OrdersStatistics',
        // path: 'TopCompaniesByOrderReport',
        children: [
          {
            path: 'TopCompaniesByOrderReport',
            element: <TopCompaniesByOrderReport />,
          },
          {
            path: 'topcdetails/:customer/:day_date/:to_date/:shipper',
            element: <TopCompaniesByOrderReportdetails />,
          },
          {
            path: 'topcdetails/city/:customer/:city/:from_date/:to_date',
            element: <TopCompaniesByCityReportdetails />,
          },
          {
            path: 'topcdetails/shipper/:customer/:shipper/:from_date/:to_date',
            element: <TopCompaniesByShipperDetails />,
          },

          {
            path: 'CompaniesOrdersByShipperReport', //RepeatedOrders
            element: <CompaniesOrdersByShipperReport />,
          },
          {
            path: 'CompaniesOrdersByCityReport',
            element: <CompaniesOrdersByCityReport />,
          },
          {
            path: 'repeatedorders',
            element: <RepeatedOrders />,
          },
        ],
      },
      {
        path: 'TodayOrders',
        element: <TodaysOrdersPage />,
      },
      {
        path: 'ShipperStatusFollow',
        element: <ShipperStatusFollowReport />,
      },
      {
        path: 'ShipperStatusFollow/:shipper_id',
        element: <ShipperStatusFollowReport />,
      },
      {
        path: 'TodayOrders/shipper/:shipper_id',
        element: <TodaysOrdersPage />,
      },
      {
        path: 'TodayOrders/customer/:client_id',
        element: <TodaysOrdersPage />,
      },
      //ShippingRequestList
      {
        path: 'shipmentrequest',
        children: [
          {
            path: '',
            element: <ShippingRequestList />,
          },
        ],
      },
      {
        path: 'Shipper',
        children: [
          {
            path: '',
            element: <ShipperList />,
          },
          {
            path: 'new',
            element: <ShipperNew />,
          },
          {
            path: 'edit/:id',
            element: <ShipperEdit />,
          },
          {
            path: 'revenue/',
            element: <ShipperRevenueReport />,
          },
          {
            path: 'revenue/:id/:name',
            element: <ShipperRevenueReport />,
          },
          {
            path: 'revenue/details/:shipper/:day_date',
            element: <ShipperRevenueDetailReport />,
          },
          {
            path: 'newrevenue/',
            element: <ShipperRevenueNewReport />,
          },
          {
            path: 'newrevenue/:id/:name',
            element: <ShipperRevenueNewReport />,
          },
          {
            path: 'newrevenue/details/:shipper/:day_date',
            element: <ShipperRevenueDetailNewReport />,
          },
        ],
      },
      {
        path: 'SiteSettings',
        element: <SiteSettings />,
      },

      {
        path: 'Configurations',
        element: <ConfigurationsEdit />,
      },
      {
        path: 'Pricing',
        children: [
          {
            path: '',
            element: <PriceList />,
          },
          {
            path: 'new',
            element: <PricingNew />,
          },
          {
            path: 'edit/:id',
            element: <PricingEdit />,
          },
          {
            path: 'pricingpage',
            element: <Pricing />,
          },
        ],
      },
      // {
      //   path: 'invoices',
      //   children: [
      //     {
      //       path: '/',
      //       element: <InvoiceList />
      //     }
      //   ]
      // },
      {
        path: 'kanban',
        element: <Kanban />,
      },
      {
        path: 'mail',
        children: [
          {
            path: '',
            element: <Navigate to="dashboard/mail/all" replace />,
          },
          {
            path: 'label/:customLabel',
            element: <Mail />,
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />,
          },
          {
            path: ':systemLabel',
            element: <Mail />,
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />,
          },
        ],
      },
      {
        path: 'adminorders',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <AdminOrderList />
              </AuthGuard>
            ),
          },
          {
            path: 'customer/:client_id',
            element: (
              <AuthGuard>
                <AdminOrderList />
              </AuthGuard>
            ),
          },
          {
            path: 'order/:order_id',
            element: (
              <AuthGuard>
                <AdminOrderList />
              </AuthGuard>
            ),
          },
          {
            path: 'shipper/:shipper_id',
            element: (
              <AuthGuard>
                <AdminOrderList />
              </AuthGuard>
            ),
          },
          {
            path: 'repeatedorder/:isRepeated',
            element: (
              <AuthGuard>
                <AdminOrderList />
              </AuthGuard>
            ),
          },
          // {
          //   path: ":ishub",
          //   element: (
          //     <AuthGuard>
          //       <AdminOrderList />
          //     </AuthGuard>
          //   ),
          // },
          {
            path: 'new/customer/:client_id',
            element: (
              <AuthGuard>
                <AdminOrderNew />
              </AuthGuard>
            ),
          },
          {
            path: 'new/shipper/:shipper_id',
            element: (
              <AuthGuard>
                <AdminOrderNew />
              </AuthGuard>
            ),
          },
          //   {
          //   path: "newwindow/:serial", //AdminOrderNewWindow
          //   element: <AdminOrderNewWindow />,
          // },
          {
            path: 'edit/:id',
            element: (
              <AuthGuard>
                <AdminOrderEdit />
              </AuthGuard>
            ),
            // element: <AdminOrderEdit />,
          },
          {
            path: 'cancelledorders',
            element: (
              <AuthGuard>
                <CancelledOrderList />
              </AuthGuard>
            ),
            // element: <CancelledOrderList />,
          },
        ],
      },
      {
        path: 'orders',
        children: [
          {
            path: 'new',
            element: (
              <AuthGuard>
                <OrderCreate />
              </AuthGuard>
            ),
            // element: <OrderCreate />,
          },
          {
            path: 'printorders',
            element: (
              <AuthGuard>
                <OrderListAdmin />
              </AuthGuard>
            ),
            // element: <OrderListAdmin />,
          },
          {
            path: ':orderId',
            element: (
              <AuthGuard>
                <OrderDetails />
              </AuthGuard>
            ),
            // element: <OrderDetails />,
          },
          {
            path: 'edit/:orderId',
            element: (
              <AuthGuard>
                <OrderEdit />
              </AuthGuard>
            ),
            // element: <OrderEdit />,
          },
          {
            path: '',
            element: (
              <AuthGuard>
                <OrderList />
              </AuthGuard>
            ),
            // element: <OrderList />,
          },

          {
            path: 'archivedorders',
            element: (
              <AuthGuard>
                <ArchivedOrderList />
              </AuthGuard>
            ),
          },
          {
            path: 'archivemanager',
            element: (
              <AuthGuard>
                <ArchiveOrderPage />
              </AuthGuard>
            ),
          },
          {
            path: 'taxable_manager',
            element: (
              <AuthGuard>
                <TaxableOrderPage />
              </AuthGuard>
            ),
          },
          {
            path: 'order_permissions',
            element: (
              <AuthGuard>
                <OrderPermissionPage />
              </AuthGuard>
            ),
          },
          
        ],
      },
      // {
      //   path: "rejectedorders",
      //   element: <RejectedOrderList/>
      // },
      {
        path: 'finance',
        element: <Finance />,
      },
      {
        path: 'backup',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <BackupList />
              </AuthGuard>
            ),
            // element: <BackupList />,
          },
          // {
          //   path: "requestbackup",
          //   //MaterialRequest
          //   element: (
          //     <AuthGuard>
          //       <CreateBackup />
          //     </AuthGuard>
          //   ),
          //   // element: <CreateBackup />,
          // },
        ],
      },
      // {
      //   path: "todays_orders",
      //   element: <TodaysOrder />,
      // },
      {
        path: 'materialrequestlist',
        element: <MaterialRequestList />,
      },
      // {
      //   path: "materialrequest",
      //   //MaterialRequest
      //   element: (
      //     <AuthGuard>
      //       <MaterialRequest />
      //     </AuthGuard>
      //   ),
      //   // element: <CreateBackup />,
      // },
      {
        path: 'CollectRequest',
        children: [
          // {
          //   path: "",
          //   element: (
          //     <AuthGuard>
          //       <CollectRequest />
          //     </AuthGuard>
          //   ),
          //   // element: <CollectRequest />,
          // },
          {
            path: 'collectList',
            element: (
              <AuthGuard>
                <CollectList />
              </AuthGuard>
            ),
            // element: <CollectList />,
          },
        ],
      },
      // {
      //   path: 'products',
      //   children: [
      //     {
      //       path: '/',
      //       element: <ProductList />
      //     },
      //     {
      //       path: 'new',
      //       element: <ProductCreate />
      //     }
      //   ]
      // },
      // {
      //   path: 'projects',
      //   children: [
      //     {
      //       path: 'browse',
      //       element: <ProjectBrowse />
      //     },
      //     {
      //       path: 'new',
      //       element: <ProjectCreate />
      //     },
      //     {
      //       path: ':projectId',
      //       element: <ProjectDetails />
      //     }
      //   ]
      // },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeed />,
          },
          {
            path: 'profile',
            element: <SocialProfile />,
          },
        ],
      },
      {
        path: 'material', //CustomerIssuePage MaterialUpdate
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <MaterialList />
              </AuthGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <AuthGuard>
                <MaterialNew />
              </AuthGuard>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <AuthGuard>
                <MaterialUpdate />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'warehouse', //CustomerIssuePage WarehouseNew WarehouseUpdate StockEntryList
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <WarehouseList />
              </AuthGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <AuthGuard>
                <WarehouseNew />
              </AuthGuard>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <AuthGuard>
                <WarehouseUpdate />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'stock_entry', //StockEntryList StockEntryNew  StockEntryEdit
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <StockEntryList />
              </AuthGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <AuthGuard>
                <StockEntryNew />
              </AuthGuard>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <AuthGuard>
                <StockEntryEdit />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'StockBalance', //StockEntryList StockEntryNew  StockBalanceReport
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <StockBalanceReport />
              </AuthGuard>
            ),
          },
        ],
      },

      {
        path: 'StockReport', //StockEntryList StockEntryNew  StockReport
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <StockReport />
              </AuthGuard>
            ),
          },
        ],
      },

      {
        path: 'CustomerMaterialBalanceReport', //StockEntryList StockEntryNew  StockReport
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <CustomerMaterialBalanceReport />
              </AuthGuard>
            ),
          },
        ],
      },

      {
        path: 'CustomerMaterialLimitList', //
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <CustomerMaterialLimitList />
              </AuthGuard>
            ),
          },
        ],
      },

      //////////
      {
        path: 'CustomerMaterialDifference', //
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <CustomerMaterialDifference />
              </AuthGuard>
            ),
          },
          {
            path: ':customer',
            element: (
              <AuthGuard>
                <CustomerMaterialDifference />
              </AuthGuard>
            ),
          },
        ],
      },
      /////////
    ],
  },
  {
    path: 'customerdashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
        {/* <CustomerDashboardLayout/> */}
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CustomerAnalytics />,
      },
      {
        //CustomerAnalytics
        path: 'customeranalytics',
        element: <CustomerAnalytics />,
      },
      {
        path: 'gallery/:shipper/:serial/:company/:mobile',
        element: <Gallery />,
      },

      {
        path: 'CollectGallery/:customer/:company_name/:collect_date',
        element: <CollectGallery />,
      },

      {
        path: 'customerissue', //CustomerIssuePage
        children: [
          {
            path: '',
            element: <CustomerIssuePage />,
          },
          {
            path: ':threadKey',
            element: <CustomerIssuePage />,
          },
        ],
      },
      {
        path: 'orders',
        children: [
          {
            path: 'new',
            element: (
              <AuthGuard>
                <OrderCreate />
              </AuthGuard>
            ),
          },
          {
            path: 'printorders',
            element: (
              <AuthGuard>
                <OrderListAdmin />
              </AuthGuard>
            ),
          },
          {
            path: ':orderId',
            element: (
              <AuthGuard>
                <OrderDetails />
              </AuthGuard>
            ),
          },
          {
            path: 'edit/:orderId',
            element: (
              <AuthGuard>
                <OrderEdit />
              </AuthGuard>
            ),
          },
          {
            path: '',
            element: (
              <AuthGuard>
                <OrderList />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'rejectedorders',
        element: <RejectedOrderList />,
      },
      {
        path: 'report',
        children: [
          {
            path: 'TopCReport',

            element: <CustomerOrdersByCityReport />,
          },
          {
            path: 'TopCReport/:from_date/:to_date',

            element: <CustomerOrdersByCityReport />,
          },
          {
            path: 'TopCReportDetails/:city/:from_date/:to_date',
            element: <CustomerOrdersByCityDetailsReport />,
          },
        ],
      },
      {
        path: 'Shipment',
        children: [
          {
            path: 'customerpricelist',
            element: <CustomerPriceList />,
          },
        ],
      },
      {
        path: 'backup',
        children: [
          // {
          //   path: "",
          //   element: (
          //     <AuthGuard>
          //       <BackupList />
          //     </AuthGuard>
          //   ),
          // },
          {
            path: 'requestbackup',
            element: (
              <AuthGuard>
                <CreateBackup />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'account/:tab',
        element: <Account />,
      },
      {
        path: 'todays_orders',
        element: <TodaysOrder />,
      },
      // {
      //   path: "materialrequestlist",
      //   element: <MaterialRequestList />,
      // },
      {
        path: 'materialrequest',
        element: (
          <AuthGuard>
            <MaterialRequest />
          </AuthGuard>
        ),
      },
      {
        path: 'revenue',
        children: [
          {
            path: '',
            element: <CustomerRevenuseReport />,
          },
          {
            path: ':from_date/:to_date',
            element: <CustomerRevenuseReport />,
          },
          {
            path: 'details/:customer/:day_date',
            element: <CustomerRevenuesDetailReport />,
          },
        ],
      },
      {
        path: 'CollectRequest',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <CollectRequest />
              </AuthGuard>
            ),
          },
          // {
          //   path: "collectList",
          //   element: (
          //     <AuthGuard>
          //       <CollectList />
          //     </AuthGuard>
          //   ),
          // },
        ],
      },
    ],
  },

  {
    path: 'adminorders',
    children: [
      // {
      //   path: "",
      //   element: <AdminOrderList />,
      // },
      // {
      //   path: "customer/:client_id",
      //   element: <AdminOrderList />,
      // },
      // {
      //   path: "shipper/:shipper_id",
      //   element: <AdminOrderList />,
      // },
      // {
      //   path: ":ishub",
      //   element: <AdminOrderList />,
      // },
      {
        path: 'newwindow/:client_id',
        element: (
          <AuthGuard>
            <AdminOrderNewWindow />
          </AuthGuard>
        ),
      },
      {
        path: 'newwindow/switch/:serial',
        element: (
          <AuthGuard>
            <AdminOrderSwitchWindow />
          </AuthGuard>
        ),
      },
      {
        path: 'new/customer/:client_id',
        element: (
          <AuthGuard>
            <AdminOrderNew />
          </AuthGuard>
        ),
      },
      {
        path: 'new/shipper/:shipper_id',
        element: (
          <AuthGuard>
            <AdminOrderNew />
          </AuthGuard>
        ),
      },
      {
        path: 'new/',
        element: (
          <AuthGuard>
            <AdminOrderNew />
          </AuthGuard>
        ),
      },
      // {
      //   path: "edit/:id",
      //   element: <AdminOrderEdit />,
      // },
      // {
      //   path: "cancelledorders",
      //   element: <CancelledOrderList />,
      // },
    ],
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="docs/overview/welcome" replace />,
      },
      {
        path: '*',
        element: <Docs />,
      },
    ],
  },
  {
    path: 'gallery/:shipper/:serial/:company/:mobile',
    element: <Gallery />,
  },
  {
    path: 'CollectGallery/:customer/:company_name/:collect_date',
    element: <CollectGallery />,
  },

  // {
  //   path: '*',
  //   element: <MainLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <Home />
  //     },
  //     {
  //       path: 'browse',
  //       element: <BrowseLayout />,
  //       children: [
  //         {
  //           path: '/',
  //           element: <Browse />
  //         },
  //         {
  //           path: '/buttons',
  //           element: <BrowseButtons />
  //         },
  //         {
  //           path: '/inputs',
  //           element: <BrowseInputs />
  //         },
  //         {
  //           path: '/charts',
  //           element: <BrowseCharts />
  //         },
  //         {
  //           path: '/colors',
  //           element: <BrowseColors />
  //         },
  //         {
  //           path: '/data-display/detail-lists',
  //           element: <BrowseDetailLists />
  //         },
  //         {
  //           path: '/data-display/quick-stats',
  //           element: <BrowseQuickStats />
  //         },
  //         {
  //           path: '/data-display/tables',
  //           element: <BrowseTables />
  //         },
  //         {
  //           path: '/forms',
  //           element: <BrowseForms />
  //         },
  //         {
  //           path: '/modals',
  //           element: <BrowseModals />
  //         },
  //         {
  //           path: '/lists/grouped-lists',
  //           element: <BrowseGroupedLists />
  //         },
  //         {
  //           path: '/lists/grid-lists',
  //           element: <BrowseGridLists />
  //         },
  //         {
  //           path: '/typography',
  //           element: <BrowseTypography />
  //         }
  //       ]
  //     },
  //     {
  //       path: 'checkout',
  //       element: <Checkout />
  //     },
  //     {
  //       path: 'pricing',
  //       element: <Pricing />
  //     },
  //     {
  //       path: '401',
  //       element: <AuthorizationRequired />
  //     },
  {
    path: '404',
    element: <NotFound />,
  },
  //     {
  //       path: '500',
  //       element: <ServerError />
  //     },
  //     {
  //       path: '*',
  //       element: <NotFound />
  //     }
  //   ]
  // }
];

export default routes;
