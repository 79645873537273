import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
    },
  },
  ar: {
    translation: {
      'Order List': 'قائمه الاوردرات',
      Import: 'استيراد',
      Export: 'تصدير',
      Collect: 'نحصيل',
      search: 'بحث',
      'Select Month': 'اختيار الشهر',
      Wallet: 'المحفظه',
      'New Order': 'اوردر جديد',
      'Request Material': 'طلب مواد',
      'Request Collect': 'طلب تحصيل',
      'Status Date': 'تاريخ الحاله',
      'Ready To Return': 'مرتجعات جاهزة للطلب',
      Revenue: 'الربح',
      'Order Status': 'حاله الاوردرات',
      'Top Cities': 'اعلي المحافظات',
      'Yesterday Orders': 'اوردرات اليوم السابق',
      Pending: 'تحت المراجعه',
      'Out For Deliver': 'خارج للتوصيل',
      Deliverd: 'تم التوصيل',
      Hold: 'معلق',
      Undelivered: 'لم يتم التوصيل',
      Undeliverd: 'لم يتم التوصيل',
      Rejected: 'مرفوض',
      Serial: 'مسلسل',
      'Order Date': 'تاريخ الطلب',
      Name: 'الاسم',
      Address: 'العنوان',
      Phone: 'موبايل',
      'Shipment Contents': 'محتوي الشحنه',
      'Shipment Content': 'محتوي الشحنه',
      Status: 'الحاله',
      'Status Note': 'ملاحظات الحاله',
      Return: 'مرتجع',
      Collected: 'تم التحصيل',
      Weigth: 'الوزن',
      Total: 'الاجمالي',
      'Total Fees': 'اجمالي الرسوم',
      'total Fees': 'اجمالي الرسوم',
      'total fees': 'اجمالي الرسوم',
      Note: 'ملاحظات',
      'From Date': 'من تاريخ',
      'To Date': 'الي تاريخ',
      'Search Order': 'بحث',
      City: 'مدينه',
      'Rejected Order List': 'قائمه الطلبات المرفوضه',
      Analytics: 'تحليلات',
      'Full Name': 'الاسم',
      'Shippment Content': 'محتوي الشحنه',
      'Total Amount': 'اجمالي القيمه',
      Notes: 'ملاحظات',
      Download: 'تحميل',
      'Client Name': 'اسم العميل',
      'Total Shipments': 'اجمالي الشحنات',
      Day: 'اليوم',
      Details: 'تفاصيل',
      All: 'الكل',
      Refresh: 'تحديث',
      Print: 'طباعه',
      'Material Request': 'طلب مواد',
      Date: 'التاريخ',
      'Collect Request': 'طلب تحصيل',
      'Customer Price List': 'قائمه اسعار العميل',
      'Todays Order': 'اوردرات اليوم',
      'Create a new Order': 'انشاء طلب جديد',
      'Create Order': 'انشاء طلب',
      Orders: 'الاوردرات',
      List: 'القائمه',
      Create: 'انشاء',
      'Print Orders': 'طباعه الاوردرات',
      'Rejected Orders': 'قائمه الاوردرات المرفوضه',
      Revenus: 'الارباح',
      'Client Price List': 'قائمه اسعار العميل',
      'Customer Issue': 'مشاكل العميل',
      'Request Pickup': 'طلب استلام الشحنات',
      'Pickup Request': 'طلب استلام الشحنات',
      Dashboard: 'القائمه الرئيسيه',
      Management: 'اداره النظام',
      'Customer Revenue Report': 'تقرير التحصيلات',
      Pickup: 'شحنه',
      'No Pickup Today': 'يوجد شحنات اليوم',
      'View Order': 'عرض الاوردرات',
      'view order': 'عرض الاوردرات',
      'Follow Status': 'عرض الاوردرات',
      'Shipper Status Follow Up': 'متابعه حالات المندوب',
      'Shipper Revenue': 'تحصيلات المندوب',
      // 'No Pickup Today':'يوجد شحنات اليوم',
      'There Exist Pickup Today': 'يوجد شحنات اليوم',
      'There is Pickup': 'يوجد شحنات اليوم',
      'There is Collect Today': 'يوجد تحصيل اليوم',
      'No Collect Today': 'لا يوجد تحصيل اليوم',

      Add: 'اضافه',
      'Copy From Another Client': 'نسخ من عميل أخر',
      'Customer Name': 'اسم العميل',
      'Company Name': 'اسم العميل',
      Delete: 'حذف',
      Shipper: 'المندوب',
      Actions: 'الوظائف',
      'Set Period': 'تعديل الفتره',
      'Unclosed Customers Issues': 'شكاوي غير مغلقه',
      'Order Delivery Status': 'حالات توصيل الاوردرات',
      'Top Companies Orders': 'اكثر الشركات اوردرات',
      'Less Companies Orders': 'اقل الشركات اوردرات',
      'Top Clients Orders': 'اكثر الشركات اوردرات',
      'Less Clients Orders': 'اقل الشركات اوردرات',
      'Top Shippers Orders': 'اكثر المندوبين اوردرات',
      'Show More': 'رؤيه المزيد',
      'Less Shippers Orders': 'اقل المندوبين اوردرات',
      'Today Out For Deliver Orders': 'خارج للتوصيل اليوم',
      'Company Credit': 'دائن الشركه',
      'Company Debit': 'مدين الشركه',
      'Top Shippers Evaluation': 'أعلى المندوبين تقييما',
      'Less Shippers Evaluation': 'أقل المندوبين تقييما',
      'Money With Shippers [Uncollected Shipper]':
        'نقود مع المندوبين [غير محصله من المندوب]',
      'Customers Money [Uncollected Client]':
        'نقود العملاء [غير محصله من العميل]',
      'CC & Uncollected Shipper': 'محصله من العميل ولم تحصل من المندوب',
      'Uncollected Client & Collected Shipper':
        'غير محصله من العميل ومحصله من المندوب',
      'Uncollected Client & Uncollected Shipper':
        'غير محصله من العميل و غير محصله من المندوب',
      Rated: 'مقيم',
      Rating: 'تقييم',
      From: 'من',
      Users: 'المستخدمين',
      User: 'مستخدم',
      EGP: 'ج.م',
      'Orders by City': 'أكثر المدن أوردرات',
      'See All': 'مشاهدة الكل',
      'Reset Password': 'تغيير الباسورد',
      Logout: 'تسجيل الخروج',
      Notifications: 'أشعارات',
      'There are no notifications': 'لايوجد أشعارات',
      'Mark all as read': 'قراءة الكل',
      'There are no alerts': 'لايوجد تذكيرات اوردرات',
      'Repeated Orders': 'أوردرات متكرره',
      'Order Alerts': 'تذكيرات اوردرات',
      'Order Alert': 'تذكير اوردر',
      'Order alert': 'تذكير اوردر',
      'order alert': 'تذكير اوردر',
      'There are no Repeated Orders': 'لايوجد اوردرات مكرره',
      Repeated: 'متكرر',
      Show: 'عرض',
      'Database Backup List': 'قاعده البيانات (النسخ الاحتياطيه)',
      Operation: 'اداره العمليات',
      Customers: 'العملاء',
      Edit: 'تعديل',
      New: 'جديد',
      Shippers: 'المندوبين',
      'All Orders': 'جميع الاوردرات',
      'Hub Orders': 'اوردرات المجمع',
      'Shipment Request': 'طلبات الشحن',
      'Follow UP Orders': 'متابعه الاوردرات',
      'Shipper Orders Review': 'مراجعة اوردرات المندوب',
      'Speed Up': 'الاستعجالات',
      Transactions: 'الحركات',
      'Shipper Closing': 'محاسبه المندوب',
      View: 'عرض',
      'Edit Approval': 'الموافقه علي التعديلات',
      'Customer Edit Approval': 'الموافقه علي تعديلات الشركات',
      'Shipper Edit Approval': 'الموافقه علي تعديلات المندوب',
      Issue: 'شكوى',
      'Collect Request List': 'قائمه طلبات التحصيل',
      'Material Request List': 'قائمه طلب المواد',
      'SMS Hold Orders': 'مراسله الاوردرات المعلقه',
      'User Evaluation': 'تقييم المستخدمين',
      Reports: 'تقارير',
      'Shipper Evaluations': 'تقييم المندوب',
      'Shipper Evaluation Details': 'تفاصيل تقييم المندوب',
      Summary: 'ملخص',
      'User Evaluations': 'تقييمات المستخدم',
      'User Evaluation Details': 'تفاصيل تقييمات المستخدم',
      'Issue Report': 'تقرير الشكاوي',
      'Orders Statistics': 'احصائيات الاوردرات',
      'Top Companies By Order': 'اعلي الشركات اوردرات',
      'Companies Orders by Shipper': 'اوردرات الشركات مجمعه بالمندوب',
      'Companies Orders by City': 'اوردرات الشركات مجمعه بالمدينة',
      'Cancelled Orders': 'الاوردرات المرفوضه',
      'Follow UP Orders Report': 'تقرير متابعه الاوردرات',
      'Shipper Tracking': 'تتبع المندوب',
      'SMS Report': 'تقرير الرسائل',
      'Shipper Transfer Report': 'تقرير تحويلات المندوب',
      'Expenses Report': 'تقرير المصاريف',
      'Shipper Custody Report': 'تقرير عهده المندوب',
      'Shipper Closing Request Report': 'تقرير طلبات محاسبه المندوب',
      Settings: 'الاعدادات',
      Zone: 'المنطقه',
      Area: 'المساحه',
      'Customer Category': 'تصنيف العميل',
      'Company Category': 'تصنيف العميل',
      WhatsApp: 'واتساب',
      'WhatsApp Group': 'مجوعات الواتساب',
      'Site Settings': 'اعدادت الموقع',
      'System Configuration': 'اعدادات النظام',
      'Pricing Setting': 'اعدادات الاسعار',
      Permissions: 'السماحيات',
      'R/C User Permissions': 'سماحيات التحصيل والمرتجع الزمنيه',
      'User Customer Permissions': 'سماحيات المسخدمين للعملاء',
      'User Shipper Permissions': 'سماحيات المستخدمين للمندوبين',
      'Shipper Transfer Permissions': 'سماحيات تحويل المندوبين',
      'User Evaluation Permissions': 'سماحيات تقييم المستخدمين',
      'Follow up Setting': 'اعدادت المتابعه',
      'Customer List': 'قائمة العملاء',
      Active: 'نشط',
      'New Message': 'رساله جديده',
      'Add Customer': 'اضافة عميل',
      'Choose Auto Collect': 'اختر حاله التحصيل الاوتو',
      'Choose Auto Pickup': 'اختر حاله البيكب الاوتو',
      Yes: 'نعم',
      No: 'لا',
      'Search Client': 'البحث بالعميل',
      'Search client': 'البحث بالعميل',
      'search client': 'البحث بالعميل',
      'Create Pickup': 'اضافه بيكب',
      'Collect Delivered': 'تحصيل الموصل',
      'Return Undelivered': 'ارجاع غير الموصل',
      'Shipment Info': 'اتفاقيه الشحن',
      'Add Order': 'أضافه أوردر',
      'Rows per page': 'عدد الصفوف في الصفحه',
      "you don't have permission to Collect Order":
        'لاتملك الصلاحيه لتحصيل الاوردر',
      "you don't have permission to open Collect for this Customer":
        'لاتملك الصلاحيه لتحصيل الاوردرات لهذا العميل',
      "you don't have permission to open Return":
        'لاتملك الصلاحيه ل ارتجاع الاوردر',
      'Deleted Successfuly': 'تم الحذف بنجاح',
      "Can't Delete": 'لايمكن الحذف',
      "you don't have permission to add order to this customer":
        'لايمكنك اضافه اوردرات لهذا العميل',
      "you don't have permission to open revenue":
        'لايمكن فتح قائمه الربح لهذا العميل',
      'Will be send to All Customers': 'سترسل الي كل عملاء',
      'Message Content': 'محتوي الرسالة',
      'Will be send to': 'سترسل الي',
      'All Customers': 'جميع العملاء',
      'Selected Customers': 'عميل مختار',
      Send: 'أرسال',
      Submit: 'تسجيل',
      Save: 'حفظ',
      Cancel: 'ألغاء',
      Customer: 'عميل',
      'Create Customer': 'أضافه عميل',
      'Create a new Customer': 'أضافه عميل جديد',
      'Full name': 'ألاسم',
      'Pickup Shipper': 'مندوب البيكب',
      'Email address': 'الايميل الألكترونى',
      'Deel Fees': 'قيمة الاتفاق',
      'Shipment Can Open': 'يمكن فتح الشحنة',
      'User Name': 'أسم المستخدم',
      Password: 'كلمه السر',
      'Confirm Password': 'تاكيد كلمة السر',
      'Mobile No': 'رقم الموبيل',
      'Show Attachment': 'عرض المرفق',

      'Upload Attachment': 'تحميل مرفق',
      'Collect Attachment': 'مرفق التحصيل',
      'Phone number is required': 'رقم الهاتف مطلوب',
      'City is required': 'المدينة مطلوبة',
      'Name is required': 'الأسم مطلوب',
      'Username is required': 'اسم المستخدم مطلوب',
      'Not Valid should have no spaces': 'يجب ان لا تحتوي علي فراغات',
      'Password must have no spaces': 'كلمه السر لايجب ان تحتوي علي فراغات',
      'Password is required': 'كلمه السر مطلوبة',
      'Passwords must match': 'يجب ان كلمه السر تكون متطابقة',
      'Customer Edit': 'تعديل العميل',
      'Auto Collect Request': 'طلب التحصيل الأوتو',
      'Collect Days': 'أيام التحصيل',
      'Auto Pickup Request': 'طلب البيكب الأوتو',
      'Pickup Days': 'أيام البيكب',
      'Update Customer': 'تعديل العميل',
      'Client sipment Info List': 'قائمه أسعار العميل',
      'Create Client Shipment Info': 'أضافه الي لقائمه أسعار العميل',
      'Edit Client Shipment Info': 'تعديل قائمه اسعار العملاء',
      'Shipper Fees': 'نسبة المندوب',
      'Copy Client Shipment Info': 'نسخ قائمة اسعار عميل',
      'Request sent!': 'تم أرسال الطلب',
      'From Company': 'الشركه',
      'Invalid Customer': 'عميل غير موجود',
      'Please Select Customer': 'بالرجاء اختيار عميل',
      'Something went wrong!': 'يوجد شئ ما خطأ',
      'Print Details': 'طباعة التفاصيل',
      Search: 'بحث',
      'Choose Submit Money Cashed Status': 'اختر حاله استلام النقديه',
      'Full Submit': 'كليا',
      'Partialy Submit': 'جزئيا',
      'No Submit': 'لم تسلم',
      'Total Orders': 'عدد الاوردرات',
      Amount: 'قيمه التحصيل',
      Fees: 'قيمه الشحن',
      COD: 'الصافى',
      Case: 'الحاله',
      'Show Details': 'عرض التفاصيل',
      'Show Detail': 'عرض التفاصيل',
      // "Status Note":"ملاحظة الحاله",
      'Money Cashed': 'استلام النقديه',
      of: 'من',
      'Updated Successfully': 'تم التحديث بنجاح',
      'Operation is scheduled': 'تم جدولة العملية',
      'Please select Customers': 'برجاء أختيار العملاء',
      'Submit Money Cash': 'أستلام النقديه',
      'Zerod Money Cash': 'لم يستلم النقديه',
      'MC Details': 'تفاصيل النقدية',
      'Go Money': 'تحصيل نقديه',
      'one Customer only must be selected': 'يجب أختيار عميل واحد فقط',
      'Select Shipper': 'أختر مندوب',
      'Please Select Shipper': 'برجاء أختيار مندوب',
      'Customer Revenue Detail': 'تفاصيل تحصيل العميل',
      Close: 'أغلاق',
      'Orders By City Report': 'حميع الاوردرات مجمعه بالمدن',
      'Orders By City Details': 'تفاصيل الاوردرات مجمعه بالمدن',
      'Assign Order To Shipper': 'تسليم الاوردرات للمناديب',
      'Receive Returns from Shipper': 'استلام المرتجعات من المناديب',
      'Delivery Returns to Customer': 'تسليم المرتجعات الي العميل',

      // Collected: "تحصيل",
      Returned: 'مرتجع',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
