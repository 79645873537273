import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import LogoutPopup from './contexts/timer';
import { PermissionProvider } from './contexts/PermissionContext';

function AppWithCallbackAfterRender() {
  return (
    <StrictMode>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <PermissionProvider>
                      <LogoutPopup />
                      <App />
                    </PermissionProvider>
                  </AuthProvider>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      </HelmetProvider>
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWithCallbackAfterRender />);

// ReactDOM.render(
//   <StrictMode>
//     <HelmetProvider>
//       <ReduxProvider store={store}>
//         <StyledEngineProvider injectFirst>
//           <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <SettingsProvider>
//               <BrowserRouter>
//                 <AuthProvider>
//                   <PermissionProvider>
//                     <LogoutPopup />
//                     <App />
//                   </PermissionProvider>
//                 </AuthProvider>
//               </BrowserRouter>
//             </SettingsProvider>
//           </LocalizationProvider>
//         </StyledEngineProvider>
//       </ReduxProvider>
//     </HelmetProvider>
//   </StrictMode>,
//   document.getElementById('root'),
// );
