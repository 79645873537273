import propTypes from 'prop-types';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import CheckIcon from '../../icons/Check';
import { useState } from 'react';

const PricingPlan = (props) => {
  const { cta, currency, description, features, image, name, popular, price, sx , main, ...other } = props;
  const LessLimit = 5 
  const [less , setLess] = useState(true)
  const [showBtn , setShowBtn] = useState(features&&features.length > LessLimit)
  const main_color = "#624e80"
  const second_color = "#ffffff"
  const main_style ={
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: main_color,
    borderRadius: 1,
    border: 2,
    borderColor: "white",
    color: second_color,
    transition:'0.4s',
    ...sx,
    '&:hover': {
      borderColor:main_color,
      // borderColor:main_color,
      borderRadius:1,
      backgroundColor: second_color,
      color: main_color,
      opacity: 10,
      
    }
  }
  const btn_style ={
    // display: 'flex',
    // flexDirection: 'column',
    // width:'90%',
    marginLeft:-2,
    padding:1,
    backgroundColor: second_color,
    color: main_color,
    transition:0.5,
    alignItems:'center',
    ...sx,
    '&:hover': {
      backgroundColor: main_color,
      color: second_color,
      opacity: 10,
    }
  }
  function ApplyShowLess(features) {
    let result = [];
  if (features && features.length !== 0) {
    const limit = less? 5 : features.length
    result = features.slice(0, limit);
  }
  return result;
  }
  const filterdData = ApplyShowLess(features)
  return (
    <Box
      sx={main_style}
      {...other}
    >
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            height: 10,
            width: 10,
            '& img': {
              height: 'auto',
              width: '80%'
            }
          }}
        >
          <img
            alt=""
            src={image}
          />
        </Box>
        <Typography
          // color="white"
          sx={{ mt: 2}}
          variant="h4"
          textAlign="center"
        >
          {name}
        </Typography>
        <Typography
          // color="textSecondary"
          sx={{ mt: 2 }}
          variant="body2"
          textAlign="center"
        >
          Within {description}
        </Typography>
      </Box>
      <Box sx={{ p:1 ,display: 'flex' ,m:'auto' , textAlign:"center"}}>
          {/* <Typography
            // color="textPrimary"
            variant="h6"
            textAlign="center"
          >
            {currency}
          </Typography> */}
          <Typography
            // color="textPrimary"
            variant="h4"
            textAlign="center"
          >
            {price}
          </Typography>
          <Typography
            // color="textSecondary"
            sx={{
              alignSelf: 'flex-end',
              ml: 1
            }}
            variant="subtitle2"
            textAlign="center"
          >
            LE/KM
          </Typography>
        </Box>
        
      <Divider />
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          m:'auto' ,
          p: 2
        }}
      >
        {filterdData.map((feature) => (
          <Box
            key={feature}
            sx={{
              alignItems: 'center',
              textAlign:"center",
              display: 'flex',
              '& + &': {
                mt: 1
              }
            }}
          >
            <CheckIcon
              fontSize="small"
              // sx={{ color: 'text.primary' }}
            />
            <Typography
              // color="textPrimary"
              sx={{
                fontWeight: 500,
                ml: 2
              }}
              variant="body2"
            >
              {feature}
            </Typography>
          </Box>
        ))}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            // display: 'flex',
            // justifyContent: 'center',
            m:'auto' ,
            mt:4 ,
            ml : -2
          }}
        >
          {showBtn&&<Button
            sx={btn_style}
            fullWidth
            // color="primary"
            onClick={(e)=>{
              // console.log('less',less)
              setLess(!less)
            }}
            variant={popular ? 'contained' : 'outlined'}
          >
            {less?"Show More" : "Show Less"}
          </Button>}
        </Box>
      </Box>
    </Box>
  );
};

PricingPlan.propTypes = {
  cta: propTypes.string.isRequired,
  currency: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  features: propTypes.array.isRequired,
  image: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  popular: propTypes.bool,
  price: propTypes.string.isRequired,
  sx: propTypes.object
};

export default PricingPlan;
