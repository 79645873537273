import {StyleSheet} from "@react-pdf/renderer";
import PropTypes from 'prop-types';

const styles = StyleSheet.create({

    page: {
      backgroundColor: '#ffffff !important',
      padding: 24,
      fontFamily: "Cairo",

   },
    h4: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.5,
      color:'white',
      backgroundColor: '#5664d2',
      width: 'auto'
  
    },
     issuestyle: {
      fontSize: 18,
      fontWeight: 800,
      lineHeight: 1.5,
      color:'#000',
      width: 'auto'

    },
    table_head: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.5,
      color:'#fff',
      backgroundColor: '#5664d2',
      width: 'auto',
      textAlign:"center"
  
    },
    print_table_head: {
      color:'#fff',
      backgroundColor: '#5664d2',
      fontSize: 14,
      fontWeight: 900,
      lineHeight: 1.5,
      // width: 'auto',
      textAlign:"center"
  
    },
    group_head: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.5,
      color:'#fff',
      // backgroundColor: '#5664d2',
      width: 'auto',
      // textAlign:"center"
  
    },
     table_row: {
      // fontSize: 14,
      // fontWeight: 800,
      // lineHeight: 1.5,
      // color:'#fff',
      // backgroundColor: '#5664d2',
      // width: 'auto',
      // textAlign:"center",
      height: 5
    },
     white: {
      position: "sticky",
        left: 0,
        zIndex:1
       // background: "white",
       //  backgroundColor: '#fff'
    },
    darkh: {
      position: "sticky",
        left: 0,
        zIndex:1
       // background: "#1f272f"

    },
    table_cell_header: {
      position: "sticky",
        left: 0,
        background: "white",
        fontSize: 14,
          fontWeight: 800,
          lineHeight: 1.5,
          color:'#fff',
          backgroundColor: '#5664d2',
          width: 'auto',


    },
    table_print: {
        fontSize: 16,
        fontWeight: 800,
        lineHeight: 1.5,
         color:'#000',
        minWidth: '50%',
        zoom:'65%',
        padding:"1px",
        maxWidth:'90%',
        marginRight:"1px",
        marginTop:"2px"
        // p:3
        // m:'auto'
    
      },
    cair: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.5,
       color:'white',
      backgroundColor: '#000',
      width: 'auto'
  
    },
    Area: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.5,
       color:'white',
      backgroundColor: '#383838',
      width: 'auto'
  
    },
    dark: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: 1.5,
       color:'white',
      backgroundColor: '#383838',
      width: 'auto'
  
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.6
    },
    subtitle2: {
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1.57
    },
    body2: {
      fontSize: 10,
      lineHeight: 1.43
    },
    gutterBottom: {
      marginBottom: 2
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    brand: {
      height: 80,
      width: 150
    },
    company: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 32
    },
    references: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10
    },
    billing: {
      marginTop: 30
    },
    items: {
      marginTop: 30
    },
    notes: {
      marginTop: 30
    },
    table: {
      display: 'flex',
      // width:200
      width: 'auto'
    },
    alignRight: {
      textAlign: 'right'
    },
    loginPage: {
      // background: '#5664d2',
      // backgroundColor: 'primary.contrastText',
      // backgroundImage : `url('./static/contact/shipmentcover.jpeg')`
      backgroundImage : `url('./static/login.png')`
      ,backgroundSize: 'cover',
    //  alignItems:"left !important",
     flex:1,
     flexDirection:'row',
     alignItems:'left',
     justifyContent:'left'
},

    sms_sent: {
      backgroundColor: 'rgb(148, 270, 175)'
      //backgroundColor: '#f50057'
    },
    sms_pending: {
      backgroundColor: 'right'
    },
    flex_container:{
        display: 'flex',
        flexDirection:'row',
        justifyContent:'right'
        //flex-wrap: nowrap;
        // justify-content: flex-start;
    },

    // loginCard:{
      
    // marginLeft:0
    
    // }
    });

 

  export default styles ;