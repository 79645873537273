import axios from 'axios';
import toast from 'react-hot-toast';
import { ServerURL } from '../../__fakeApi__/ServerConfig'
const baseURL = ServerURL;

const axiosInstance = axios.create({
	baseURL: `${baseURL}api/`,
	timeout: 5000,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		let message = !(error.response) ? "" :  error.response.data.detail;
		// console.log("error ===> ", error.response)
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			message = 'Network Error'
			toast.error(message);
			// toast.error
			return Promise.reject(message);
		} else if (
			error.response.status === 401 && originalRequest.url === baseURL + 'token/refresh/'
		) {
			// message = 'Login faild please check your username and password'
			// toast.error(message);
			window.location.href = '/login/';
			return Promise.reject(message);
		} else if (
			error.response.status === 401
		) {
			message = 'Login faild please check your username and password'
			toast.error(message);
			// window.location.href = '/login/';
			return Promise.reject(message);
		}

		else if (
			error.response.data.code === 'token_not_valid' && error.response.status === 401 && error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);

				if (tokenParts.exp > now) {
					return axiosInstance
						.post('/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('access_token', response.data.access);
							localStorage.setItem('refresh_token', response.data.refresh);

							axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;

							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							// console.log(err);
						});
				} else {
					window.location.href = '/login/';
				}
			} else {
				window.location.href = '/login/';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(message);
	}
);

export default axiosInstance;

