import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { UserApi } from "../../../__fakeApi__/UserApi";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
} from "@material-ui/core";
//import OrderList from '../../../pages/dashboard/OrderList';
import useMounted from "../../../hooks/useMounted";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import CurrencyDollarIcon from "../../../icons/CurrencyDollar";
import { Formik } from "formik";

// import { Box, Button, Container, Grid, Typography } from '@material-ui/core';

const ResetPasswordModal = (props) => {
  const { onApply, onClose, open, ...other } = props;
  const navigate = useNavigate();
  const mounted = useMounted();
  // const [orders,setOrders] = useState([])

  const passwordReset = async (values) => {
    // toast.success('Request sent!');
    // try {
    let response = await UserApi.reset_password(values);

    if (response.ok) {
      response.json().then((result) => {
        toast.success(result.message);
      });
      onApply();
    } else {
      response.json().then((result) => {
        toast.error(result.message);
      });
    }
    // } catch (err) {
    //   console.error(err);
    // }
  };
  const password_validate = (value) => /^[A-Za-z._@0-9]{6,}$/.test(value);

  return (
    <Dialog width="lg" onClose={onClose} open={open} {...other}>
      <Box sx={{ p: 3 }}>
        <Formik
          initialValues={{
            new_password: "",
            password: "",
            confirm_password: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(6, "Must be at least 6 characters")
              .max(255)
              .required("Required"),
            new_password: Yup.string()
              .min(6, "Must be at least 6 characters")
              .test(
                "Password",
                "Password must have no spaces",
                password_validate
              )
              .max(255)
              .required("Required"),
            confirm_password: Yup.string()
              .oneOf([Yup.ref("new_password"), null], "Passwords must match")
              .required("Required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await passwordReset(values);

              //   navigate("/authentication/login");
            } catch (err) {
              console.error(err);
              if (mounted.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.new_password && errors.new_password)}
                fullWidth
                helperText={touched.new_password && errors.new_password}
                label="New Password"
                margin="normal"
                name="new_password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.new_password}
                variant="outlined"
              />
              <TextField
                error={Boolean(
                  touched.confirm_password && errors.confirm_password
                )}
                fullWidth
                helperText={touched.confirm_password && errors.confirm_password}
                label="Password Confirmation"
                margin="normal"
                name="confirm_password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirm_password}
                variant="outlined"
              />
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 3 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Reset Password
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

ResetPasswordModal.propTypes = {
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default ResetPasswordModal;
