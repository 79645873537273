import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@material-ui/core';
import NavItem from './NavItem';
import useAuth from '../hooks/useAuth';

const renderNavItems = ({ depth = 0, items, pathname , user }) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth,
        user
      }), []
    )}
  </List>
);

function checkUrl(input, words) {
  // console.log('inputssssss',input , words)
  if (input == "") {
    return true;
  }
  return words.some(word => input.toLowerCase()==word.toLowerCase());
  // return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
 }



const reduceChildRoutes = ({ acc, pathname, item, depth , user }) => {
  const key = `${item.title}-${depth}`;
  // const permitted_url = (localStorage.getItem("permitted_url") || '').split(',') || []
  const permitted_url = user.permitted_url || ['dashboard']
  // console.log('permitted_urls' , permitted_url)
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;
  // console.log(item.path)
  
  // if ( user && user.is_staff ==true && user.is_superuser == false && checkUrl(item.path,user.permitted_url || []) == false){
    if ( user && user.is_staff ==true && user.is_superuser == false && checkUrl(item.path,permitted_url || []) == false){
      return acc;
  }
  if (item.children) {
    if (user && user.is_staff == true && user.is_superuser == false) {
      let valid = false;
      item.children.forEach((e) => {
        if (checkUrl(e.path, permitted_url || []) == true) {
          valid = true;
        }
      });
      if (!valid) {
        return acc;
      }
    }

    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
          user
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, ...other } = props;
  const {user} = useAuth()
  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase'
          }}
        >
          {title}
        </ListSubheader>
      )}
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        user
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
