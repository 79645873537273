import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { subDays, subHours } from "date-fns";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import BellIcon from "../../icons/Bell";
import ChatAltIcon from "../../icons/ChatAlt";
import CreditCardIcon from "../../icons/CreditCard";
import ArrowRightIcon from "../../icons/ArrowRight";
import { SocketHost } from "../../__fakeApi__/ServerConfig";
import ShoppingCartIcon from "../../icons/ShoppingCart";
// import socketIOClient from "socket.io-client";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { NotificationsApi } from "../../__fakeApi__/NotificationsApi";
import useMounted from "../../hooks/useMounted";
import useAuth from "../../hooks/useAuth";
import ScrollBar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { DateApi } from "../../__fakeApi__/DateApi";

const now = DateApi.getDate();

// const notifications = [
//   {
//     id: '5e8883f1b51cc1956a5a1ec0',
//     createdAt: subHours(now, 2).getTime(),
//     description: 'Dummy text',
//     title: 'Your order is placed',
//     type: 'order_placed'
//   },
//   {
//     id: '5e8883f7ed1486d665d8be1e',
//     createdAt: subDays(now, 1).getTime(),
//     description: 'You have 32 unread messages',
//     title: 'New message received',
//     type: 'new_message'
//   },
//   {
//     id: '5e8883fca0e8612044248ecf',
//     createdAt: subDays(now, 3).getTime(),
//     description: 'Dummy text',
//     title: 'Your item is shipped',
//     type: 'item_shipped'
//   },
//   {
//     id: '5e88840187f6b09b431bae68',
//     createdAt: subDays(now, 7).getTime(),
//     description: 'You have 32 unread messages',
//     title: 'New message received',
//     type: 'new_message'
//   }
// ];

const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon,
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const mounted = useMounted();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(
    notifications.length
  );
  const { t } = useTranslation();
  const [response, setResponse] = useState("");
  const { user } = useAuth();
  const ENDPOINT = `ws://${SocketHost}/ws/room/${user.id}/`;

  // const [client , setClient] = useState(new W3CWebSocket(ENDPOINT))
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);
  const [read_all, setReadAll] = useState(false);

  function recieve_notifications() {
    // client.onopen = () => {
    //   // console.log('message' , 'open')
    // };
    // client.onmessage = (message) => {
    //   // console.log('message' , message)
    //   setResponse(message)
    // };
  }

  const GetDataList = useCallback(async () => {
    // if (mounted.current) {

    const data = await NotificationsApi.get_notifications(user.id);
    setNotifications(data.length ? data : []);
    setNotificationCount(data.filter((x) => !x.is_read).length);
    setCount(Math.ceil(parseFloat(notifications.length / 10, 10)));
    // }
  }, [response, open, read_all]);

  useEffect(() => {
    GetDataList();
  }, [GetDataList]);
  useEffect(() => {
    recieve_notifications();
    setCount(Math.ceil(parseFloat(notifications.length / 10, 10)));
  }, [mounted, response, open, count, read_all]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function mark_all_as_read(event) {
    const data = NotificationsApi.mark_all_as_read(user.id);
    setReadAll(true);
    handleClose();
  }
  async function mark_as_read(id) {
    const data = await NotificationsApi.mark_as_read(user.id, [id]);
  }
  const applyPagination = (data, page, limit) =>
    data.slice(page * limit, page * limit + limit);

  const handlePageChange = (event) => {
    let value = parseInt(event.target.textContent, 10) - 1;
    setPage(value);
  };

  const paginated = applyPagination(notifications, page, 10);

  return (
    <>
      <Tooltip title={t("Notifications")}>
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge color="error" badgeContent={notificationCount}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 500, maxHeight: 600 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            {t("Notifications")}
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {t("There are no notifications")}
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Pagination
                count={count}
                size="small"
                onChange={handlePageChange}
              />
            </Box>

            <ScrollBar>
              <List disablePadding sx={{ height: 400 }}>
                {paginated.map((notification) => {
                  // const Icon = iconsMap[notification.type];
                  const Icon = ChatAltIcon;

                  return (
                    <ListItem divider key={notification.id}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Link
                            color="textPrimary"
                            sx={{ cursor: "pointer" }}
                            underline="none"
                            variant="subtitle2"
                          >
                            {notification.subject}
                          </Link>
                        }
                        secondary={notification.content}
                      />

                      <IconButton
                        edge="end"
                        sx={{
                          backgroundColor: "primary.main",
                          color: "primary.contrastText",
                        }}
                        onClick={async () => {
                          await mark_as_read(notification.id);
                          navigate(notification.url || "", {
                            state: {
                              id: notification.realated_entity_id,
                            },
                          });
                          handleClose();
                        }}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            </ScrollBar>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={mark_all_as_read}
              >
                {t("Mark all as read")}
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
