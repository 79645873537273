import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import ContactsPopover from "./ContactsPopover";
import ContentSearch from "./ContentSearch";
import LanguagePopover from "./LanguagePopover";
import Logo from "../Logo";
import NotificationsPopover from "./NotificationsPopover";
import RepeatedOrdersPopover from "./RepeatedOrdersPopover";
import OrderAlertsPopover from "./OrderAlertsPopover";
import { useState, useCallback, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { blogApi } from "../../__fakeApi__/blogApi";
import { ServerURL,LogoHeight,LogoWidth } from "../../__fakeApi__/ServerConfig";
const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const CustomerDashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user } = useAuth();
  // const [contact, setContact] = useState({});
  const [logo, setLogo] = useState("/static/contact/qplogo.png");

  const getContacts = useCallback(async () => {
    try {
      const data = await blogApi.getContacts(); //http://127.0.0.1:8000/settings/get_user_settings

      if (data.settings) {
        if (data.settings.logo) {
          setLogo(
            `${ServerURL.substring(0, ServerURL.length - 1)}${
              data.settings.logo
            }`
          );
        }
        // setContact(data.settings);
      }
    } catch (err) {
      console.error(err);
    }
  });
  useEffect(() => {
    getContacts();
  }, []);
  const OrderAlertShowFlag = user.is_staff == true || user.is_superuser == true;
  const RepeatedOrdersShowFlag =
    user.is_staff == true || user.is_superuser == true;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          {/* <img
                  // className="image2"
                  alt="hello"
                  src="/static/contact/qplogo.png"
                  style={{ width: "110%" }}
                /> */}
          <img
            // className="image2"
            alt="hello"
            src={logo}
            width={LogoWidth}
            height={LogoHeight}
            // style={{ width: "110%" }}
          />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <LanguagePopover /> */}
        <Box sx={{ ml: 1 }}>{/* <ContentSearch /> */}</Box>
        <Box sx={{ ml: 1 }}>{/* <ContactsPopover /> */}</Box>
        {/* { OrderAlertShowFlag == true ?  (<Box sx={{ ml: 1 }}>
          <OrderAlertsPopover /> 
        </Box>) : ''} */}

        <Box sx={{ ml: 1 }}>
          {RepeatedOrdersShowFlag && <RepeatedOrdersPopover />}
        </Box>
        <Box sx={{ ml: 1 }}>{OrderAlertShowFlag && <OrderAlertsPopover />}</Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>

        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

CustomerDashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default CustomerDashboardNavbar;
