import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { authApi } from "../__fakeApi__/authApi";
import Login from "../pages/authentication/Login";
import LogoutPopup from "./timer";
import { remove } from "nprogress";
import AuthGuard from "../components/AuthGuard";
import { SessionTimeinMinutes } from "../__fakeApi__/ServerConfig";
import { DateApi } from "../__fakeApi__/DateApi";
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    // console.log(action.payload)
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    // console.log("login user " , user)
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const accessToken = await authApi.login({ email, password });
    const user = await authApi.me(accessToken);
    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    localStorage.clear();
    sessionStorage.clear();

    dispatch({ type: "LOGOUT" });
  };

  const initialize = async () => {
    try {
      let old_user = localStorage.getItem("user");
      if (!old_user) {
        // return
        logout();
        dispatch({
          type: "LOGOUT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        localStorage.clear();
        // dispatch({ type: "LOGOUT" });
        // window.location.reload()
        //  return <Login />;
      }
      // console.log("session expierd timer", localStorage.getItem("date"));
      let timer = DateApi.getDate(localStorage.getItem("date")); //Date.parse(localStorage.getItem('date'))

      let now = DateApi.getDate();
      timer.setMinutes(timer.getMinutes() + 1);
      if (timer >= now) {
        let time_now = DateApi.getDate();
        time_now.setMinutes(timer.getMinutes() + SessionTimeinMinutes);
        let datestring = DateApi.getDate(time_now);
        localStorage.setItem("date", datestring);
      }
      if (timer < now && localStorage.getItem("date")) {
        // dispatch({ type: "LOGOUT" });

        console.log("session expierd timer", timer);
        console.log("session expierd timer date", DateApi.getDate());
        localStorage.clear();
        // sessionStorage.clear();
        // console.log("session expierd", DateApi.getDate());
        // window.location.reload()

        // dispatch({
        //   type: "INITIALIZE",
        //   payload: {
        //     isAuthenticated: false,
        //     user: null,
        //   },
        // });
      }
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        const user = await authApi.me(accessToken);
        // delete user["password"];
        // const user = {"id" : localStorage.getItem('userid') ,
        //               "name" :localStorage.getItem('username') ,
        //               "token": localStorage.getItem('Token')  ,
        //               "is_staff" :true ? localStorage.getItem('is_staff') == 'true' : false ,
        //               "is_superuser" : true ? localStorage.getItem("is_superuser") == 'true' : false ,
        //               "permitted_url" : localStorage.getItem('permitted_url')
        //             }
        // await authApi.me(accessToken);
        // console.log("login" , user)
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);
    localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };
  // if (!localStorage.getItem("user")) {
  //   return <AuthGuard />;
  // }

  return (
    <>
      {/* <AuthGuard /> */}
      <AuthContext.Provider
        value={{
          ...state,
          platform: "JWT",
          login,
          logout,
          register,
        }}
      >
        {/* <AuthGuard/> */}
        {children}

        <LogoutPopup />
      </AuthContext.Provider>
    </>
    // </AuthGuard>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
