import { useEffect, useState , useCallback } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "./styles.css";
import {
  Box,
  Button,
  Drawer,
  Fab,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
// import { THEMES } from '../constants';
// import AdjustmentsIcon from '../icons/Adjustments';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// import { blogApi } from "../../__fakeApi__/blogApi";
import useMounted from '../../hooks/useMounted';

const WhatsappDrawer = (props) => {
  const location = useLocation();
  // const [contact, setContact] = useState({});
  const mounted = useMounted();
  const urls = [
    "/",
    "/home",
    "/pricing",
    "/about",
    "/service",
    "/contact",
    "/pricing",
    "/login",
    "/customerdashboard"
  ];
  const showBtn = urls.some(
    (word) => location.pathname.toLowerCase() == word.toLowerCase()|| 
    location.pathname.toLowerCase().includes("/customerdashboard")
  );

  // const getContacts = useCallback(async () => {
  //   try {
  //     const data = await blogApi.getContacts(); //http://127.0.0.1:8000/settings/get_user_settings

  //     if (mounted.current) {
  //       setContact(data.settings || {});
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // });
  // useEffect(() => {
  //   getContacts();
  // }, []);
  return (
    <>
      {showBtn && (
        <Tooltip title="Message Us">
          <Fab
            // color="primary"
            // onClick={handleOpen}
            className="whatsAppIcon"
            size="medium"
            sx={{
              bottom: 0,
              margin: (theme) => theme.spacing(4),
              position: "fixed",
              // left: 0,
              right: 0,
              zIndex: (theme) => theme.zIndex.speedDial,
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://api.whatsapp.com/send/?phone=${props.phone}&text&app_absent=0`}
            >
              <WhatsAppIcon className="whatsAppIcon" />
            </a>

            {/* <AdjustmentsIcon fontSize="small" /> */}
          </Fab>
        </Tooltip>
      )}
    </>
  );
};

export default WhatsappDrawer;
