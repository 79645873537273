import createSvgIcon from "@material-ui/core/utils/createSvgIcon";

const Facebook = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="26px"
    height="26px"
  >
    <path d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z" />
    <path
      fill="#fff"
      d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"
    />
  </svg>,
  "facebook"
);

export default Facebook;
