import { useCallback, useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import "../../components/contacts/contacts.css";
import { PricingPlan } from "../../components/pricing";
import { Helmet } from "react-helmet-async";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SendIcon from "@material-ui/icons/Send";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { blogApi } from "../../__fakeApi__/blogApi";
import { BlogPostCard, BlogNewsletter } from "../../components/blog";
import PlusIcon from "../../icons/Plus";
import SortAscendingIcon from "../../icons/SortAscending";
import SortDescendingIcon from "../../icons/SortDescending";
import SearchIcon from "../../icons/Search";
import useMounted from "../../hooks/useMounted";
import gtm from "../../lib/gtm";
import { StyleSheet } from "@react-pdf/renderer";
import PricingFooter from "./PricingFooter";
import PricingHeader from "./PricingHeader";
import User from "../../icons/User";
import { PostConstruct } from "ag-grid-community";
import PricingCarousel from "./PricingCarousel";

import ReplyIcon from "@material-ui/icons/Reply";

const btn_style = {
  backgroundColor: "#624e80",
  color: "white",
  // height: "45px",
  // width: "100px",
  borderRadius: 0.5,
  m: "auto",
  mt: 2,
  "&:hover": {
    backgroundColor: "#623e80",
    color: "white",
    opacity: 10,
    transition: 0.5,
  },
};

const sortOptions = [
  {
    label: "Newest",
    value: "desc",
    icon: SortDescendingIcon,
  },
  {
    label: "Older",
    value: "asc",
    icon: SortAscendingIcon,
  },
];

const PricingPage = () => {
  const mounted = useMounted();
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [posts, setPosts] = useState([]);
  const [contact, setContact] = useState([]);
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  const getPosts = useCallback(async () => {
    try {
      const data = await blogApi.getpricelist(); //http://127.0.0.1:8000/settings/get_user_settings

      if (mounted.current) {
        setPosts(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  const getContacts = useCallback(async () => {
    try {
      const data = await blogApi.getContacts(); //http://127.0.0.1:8000/settings/get_user_settings

      if (mounted.current) {
        setContact(data.settings);
      }
    } catch (err) {
      console.error(err);
    }
  });
  useEffect(() => {
    getPosts();
  }, [getPosts]);
  useEffect(() => {
    getContacts();
  }, []);

  const handleSortOpen = () => {
    setOpenSort(true);
  };

  const handleSortClose = () => {
    setOpenSort(false);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setOpenSort(false);
  };

  const { icon: SortOptionIcon } = sortOption;

  return (
    <>
      <Helmet>
        <title>Pricing: Price List | {contact.company || "Dynamic Shipping System"}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#fff",
          minHeight: "100%",
        }}
      >
        <div>
          <PricingHeader contact={contact} />
          <Box
            className="cover"
            sx={{
              alignItems: "center",
              // border: 1,
              // borderRadius: 1,
              // borderColor: "divider",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              outline: "none",
              p: 1,
              mt: 3,
              // "&:hover": {
              //   backgroundColor: "action.hover",
              //   cursor: "pointer",
              //   opacity: 0.5,
              // },
            }}
          >
            <Grid
              alignItems="center"
              textAlign="center"
              sx={{ justifyContent: "center", display: "flex" }}
              container
              spacing={4}
              m="auto"
            >
              <Grid
                item
                md={12}
                xs={12}
                // sx={{
                //   // display: {
                //   //   md: "flex",
                //   //   // xs: "none",
                //   // },
                //   // justifyContent: "center",
                // }}
              >
                <Typography
                  sx={{
                    m: "auto",
                    color: "#fff",
                    align: "center",
                    fontSize: 40,
                    fontFamily: "Gilroy",
                  }}
                  variant="h1"
                >
                  Price Offers
                </Typography>
                <Typography
                  sx={{
                    m: "auto",
                    color: "#fff",
                    align: "center",
                    fontSize: 30,
                    fontFamily: "Gilroy",
                  }}
                  variant="h6"
                >
                  Home / Price Offers
                </Typography>
              </Grid>
            </Grid>

            {/* <div className="image1Parent"> */}
            {/* <img
              className="image1"
              alt="hello"
              src="/static/newPages/ComponentTMP_0-image.png"
              style={{
                height: 439,
                width: 1440,
                marginTop: 0
                ,opacity : 0.84
                , color:'#172b4d',
                padding: -10
                ,background :  'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)'
                ,backgroundImage:'linear-gradient(to right, red , yellow)'
               , transform: "translate(-3, 117)"}}
            />
          </div> */}
          </Box>

          {/* <div className="overlay">
            <RouterLink to="/">
              <div className="loginbtn">
                <Button
                  color="warning"
                  component={RouterLink}
                  size="small"
                  startIcon={<User fontSize="small" />}
                  to="/login"
                  variant="outlined"
                >
                  Login
                </Button>
              </div>
            </RouterLink>
            <img
              className=".image1"
              alt="hello"
              src="/static/contact/shipmentcover.jpeg"
              style={{ height: 500, width: "100%", marginTop: -50 }}
            />
          </div> */}

          {/* <div>
            <img
              className="image2"
              alt="hello"
              src="/static/contact/1549292064_701.png"
              style={{ maxWidth: "100%", left: "20%", width: "50%" }}
            />
          </div> */}
        </div>
        <Box sx={{ py: 6 }}>
          <Container maxWidth="lg">
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            ></Box>
            {/* <div style={styles.pricediv}>
              <h1>Pricing</h1>
            </div> */}
            <Box sx={{ mt: 6 }}>
              <PricingCarousel />
            </Box>

            <Box
              sx={{
                mt: 6,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid
                alignItems="center"
                textAlign="center"
                sx={{ justifyContent: "center", display: "flex" }}
                container
                spacing={4}
                m="auto"
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  // sx={{
                  //   // display: {
                  //   //   md: "flex",
                  //   //   // xs: "none",
                  //   // },
                  //   // justifyContent: "center",
                  // }}
                >
                  <Typography
                    sx={{
                      m: "auto",
                      // color: "#fff",
                      align: "center",
                      fontSize: 40,
                      fontFamily: "Gilroy",
                    }}
                    variant="h1"
                    color="#000"
                  >
                    We are ready to help you
                  </Typography>
                  <Typography
                    sx={{
                      m: "auto",
                      // color: "#fff",
                      align: "center",
                      fontSize: 30,
                      fontFamily: "Gilroy",
                    }}
                    color="#000"
                    variant="h6"
                  >
                    Do you need a specific inquiry contact us?
                  </Typography>
                  <RouterLink style={{ textDecoration: "none" }} to="/">
                    <Button
                      sx={btn_style}
                      component={RouterLink}
                      size="small"
                      endIcon={<SendIcon fontSize="small" />}
                      to="/contact"
                      variant="outlined"
                      // mt={40}
                    >
                      Contact Us
                    </Button>
                  </RouterLink>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        {/*<BlogNewsletter />*/}
        <PricingFooter contact={contact} />
      </Box>
    </>
  );
};

export default PricingPage;
