import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Divider, Link, Toolbar ,Button } from '@material-ui/core';
import Logo from '../Logo';
import User from '../../icons/User';

const BlogNavbar = (props) => (
  <AppBar
    elevation={0}
    sx={{ backgroundColor: 'background.paper' }}
    {...props}
  >
    <Toolbar sx={{ minHeight: 64 }}>
     <RouterLink to='/'>
         <Button
                    color="primary"
                    component={RouterLink}
                    size="small"
                    startIcon={<User fontSize="small" />}
                    to='/login'
                    variant="outlined"
                  >
                    Login
        </Button>
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />
      <Link
        color="textSecondary"
        component={RouterLink}
        to="/"
        underline="none"
        variant="body1"
      >
        Home
      </Link>
      <Link
        color="textPrimary"
        component={RouterLink}
        to="/about"
        underline="none"
        sx={{ mx: 2 }}
        variant="body1"
      >
        About
      </Link>
      <Link
        color="textSecondary"
        component={RouterLink}
        to=""
        underline="none"
        variant="body1"
      >
        Find Us
      </Link>
    </Toolbar>
    <Divider />
  </AppBar>
);

export default BlogNavbar;
