import { useEffect, useState , useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import AuthBanner from "../../components/authentication/AuthBanner";
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT,
} from "../../components/authentication/login";
import Logo from "../../components/Logo";
import useAuth from "../../hooks/useAuth";
import gtm from "../../lib/gtm";
import styles from "../../components/dashboard/Styles";
import PricingHeader from '../pricingpage/PricingHeader'
import { blogApi } from "../../__fakeApi__/blogApi";
const platformIcons = {
  Amplify: "/static/icons/amplify.svg",
  Auth0: "/static/icons/auth0.svg",
  Firebase: "/static/icons/firebase.svg",
  JWT: "/static/icons/jwt.svg",
};

const Login = () => {
  const { platform } = useAuth();
  localStorage.removeItem("replies")
  localStorage.removeItem("issue")
  const [contact , setContact] = useState({})
  // const zooming = browser.tabs.setZoom(1,  0.3)
  // const [logo, setLogo] = useState("/static/contact/qplogo.png");

  const getContacts = useCallback(async () => {
    try {
      const data = await blogApi.getContacts(); //http://127.0.0.1:8000/settings/get_user_settings

      if (data.settings) {
        setContact(data.settings);
      }
    } catch (err) {
      console.error(err);
    }
  });
  useEffect(() => {
    getContacts();
  }, []);
  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <div style={styles.loginPage}>
      <Helmet>
      <title>Login | {contact.company || "Dynamic Shipping System"}</title>
      </Helmet>
      <Box
        sx={{
          // backgroundColor: '#000000',
          display: "flex",
          flexDirection: "row",
          minHeight: "100vh",
          // backgroundColor: '#5664d2',
        }}
      >
        {/* <AuthBanner /> */}
        <PricingHeader contact = {contact}/>
        <Container style={styles.loginCard} maxWidth="sm" sx={{ py: "80px" , mt:20 , ml:6 , mr:6}}>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
              ml:5
            }}
          >
            <RouterLink to="/">
            {/* <img
                    // className="image2"
                    alt="hello"
                    src="/static/contact/1549292064_701.png"
                    style={{  width:"75%" }}
                  /> */}
              {/* <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              /> */}
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 0,
                  ml:0
                }}
              >
                <div sx={{textAlign:'center'}}>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log In
                  </Typography>
                  
                </div>
                <Box
                  sx={{
                    height: 32,
                    "& > img": {
                      maxHeight: "100%",
                      width: "auto",
                    },
                  }}
                >
                 
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {platform === "JWT" && <LoginJWT />}
              </Box>
              <Divider sx={{ my: 3 }} />
              {/* <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Create new account
              </Link>
              {platform === 'Amplify' && (
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  sx={{ mt: 1 }}
                  to="/authentication/password-recovery"
                  variant="body2"
                >
                  Forgot password
                </Link> */}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
};

export default Login;
